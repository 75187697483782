import React, { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { localStorageData } from '../../helpers/localStorage'
import { useAppSelector } from '../../hooks/redux'
import Header from './Header'

import Main from './Main'
import SideBar from './sideBar/SideBar'

const ProtectedRoute = () => {
	const token = localStorageData.getData('authData')
	const userDetails = useAppSelector(state => state.auth.userDetails)
	const userPermission = useAppSelector(state => state.auth.userPermission)
	const loggedIn = token && userDetails && userPermission.length > 0

	const [isOpenMenu, setIsOpenMenu] = useState(false)

	const onToggleMenu = () => {
		setIsOpenMenu(prevOpen => !prevOpen)
	}

	if (loggedIn) {
		return (
			<>
				<Header isOpenMenu={isOpenMenu} onToggleMenu={onToggleMenu} withSideBar />
				<SideBar isOpenMenu={isOpenMenu} onToggleMenu={onToggleMenu} />
				<Main>
					<Outlet />
				</Main>
			</>
		)
	} else if (!token) {
		return <Navigate to="/login" />
	} else {
		return null
	}
}

export default ProtectedRoute

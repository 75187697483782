import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import CustomDrawer from './CustomDrawer'


type SideBarProps = {
	isOpenMenu: boolean
	onToggleMenu: () => void

}

const SideBar = ({ isOpenMenu, onToggleMenu }: SideBarProps) => {
	
	return (
		<>
			<CustomDrawer isOpenMenu={isOpenMenu} onToggleMenu={onToggleMenu} />
		</>
	)
}

export default SideBar

import { Button, styled } from '@mui/material'

export const StyledCustomButton = styled(Button)(({ theme }) => ({
	minWidth: '158px',
	maxWidth: '170',
	height: '46px',
	// backgroundColor: theme.palette.info.light, //
	backgroundColor: '#2D82F5',
	borderRadius: '10px',
	fontSize: '16px',
	fontWeight: 500,
	color: '#FFF',
	textTransform: 'initial',
	'&:hover': {
		backgroundColor: theme.palette.info.dark,
	},
	[theme.breakpoints.down('md')]: {
		minWidth: '145px',
		fontSize: '16px',
	},
}))

export const StyledSecondaryButton = styled(StyledCustomButton)(({ theme }) => ({
	backgroundColor: '#AFAFAF',
	'&:hover': {
		backgroundColor: '#3F4153',
	},
}))

type CustomButtonProps = {
	children: string
	type: 'button' | 'submit'
	className?: string
	disabled?: boolean
	form?: string
	onClick?: () => void
}

export const CustomButton = ({
	children,
	onClick,
	type,
	form,
	disabled,
	className,
}: CustomButtonProps) => {
	return (
		<StyledCustomButton
			variant="contained"
			onClick={onClick}
			type={type}
			form={form}
			{...(disabled && { disabled })}
			{...(className && { className })}
		>
			{children}
		</StyledCustomButton>
	)
}

export const SecondaryButton = ({ children, onClick, type, form }: CustomButtonProps) => {
	return (
		<StyledSecondaryButton variant="contained" onClick={onClick} type={type} form={form}>
			{children}
		</StyledSecondaryButton>
	)
}

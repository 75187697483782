import React, { useEffect, useMemo } from 'react'
import MultipleSelectForm from '../../ui/MultipleSelectForm'
import { CustomerTableFilterFormType } from '../../../types/customersTypes'
import { useForm } from 'react-hook-form'
import { Button, Stack } from '@mui/material'
import { useAppSelector } from '../../../hooks/redux'
import { isKwlMAPermission } from '../../../helpers/utils/auth'
import TextFieldForm from '../../ui/TextFieldForm'

type CustomerTableFilterFormProps = {
	onFilterFormSubmit: (dataForm: CustomerTableFilterFormType) => void
	formState: CustomerTableFilterFormType
}

const CustomerTableFilterForm = ({
	onFilterFormSubmit,
	formState,
}: CustomerTableFilterFormProps) => {
	const { inviterList, inviteLinkList } = useAppSelector(
		state => state.customers.filterList,
	)
	const { userPermission } = useAppSelector(state => state.auth)

	const hasKwlMAPermission = isKwlMAPermission(userPermission)

	const {
		handleSubmit,
		control,
		setValue,
		// formState: test,
	} = useForm<CustomerTableFilterFormType>()

	const modifiedInviterListForFilter = useMemo(() => {
		return inviterList.map(item => ({
			id: item.id,
			name: `${item.firstName} ${item.lastName}`,
		}))
	}, [inviterList])

	useEffect(() => {
		setValue('inviteLinkIds', formState.inviteLinkIds)
		setValue('inviterIds', formState.inviterIds)
		setValue('query', formState.query)
	}, [formState.inviteLinkIds, formState.inviterIds, formState.query, setValue])

	const onSubmit = (dataForm: CustomerTableFilterFormType) => {
		let modifiedDataForm = {
			...dataForm,
			query: dataForm.query ? dataForm.query.trim() : '',
		}

		onFilterFormSubmit(modifiedDataForm)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<MultipleSelectForm<CustomerTableFilterFormType>
				label="Invite Links"
				control={control}
				name="inviteLinkIds"
				options={inviteLinkList}
			/>
			{hasKwlMAPermission && (
				<MultipleSelectForm<CustomerTableFilterFormType>
					label="Agents"
					control={control}
					name="inviterIds"
					options={modifiedInviterListForFilter}
				/>
			)}
			<TextFieldForm<CustomerTableFilterFormType>
				name="query"
				control={control}
				label="Customer Name, Phone Number"
				type="text"
				inputMode="text"
				maxLength={64}
			/>
			<Stack justifyContent="center" alignItems="center">
				<Button variant="outlined" type="submit">
					Apply Filters
				</Button>
			</Stack>
		</form>
	)
}

export default CustomerTableFilterForm

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { stat } from 'fs'
import { UserDetails, UserPermission } from '../../types/authTypes'
import { CustomErrorResponse } from '../../types/axiosTypes'
import { authActionCreators } from '../actionCreators/authActionCreators'
// import { setError } from './notificationSlice'

type AuthState = {
	isLoading: boolean
	isLoaded: boolean
	error: undefined | CustomErrorResponse
	loggedIn: boolean
	userDetails: UserDetails | null
	userPermission: Array<UserPermission>
	// error: any
}

const initialState: AuthState = {
	isLoading: false,
	isLoaded: false,
	error: undefined,
	loggedIn: false,
	userDetails: null,
	userPermission: [],
	// auth: null,
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setLogInState(state) {
			state.loggedIn = true
		},
	},
	extraReducers: builder => {
		builder
			.addCase(authActionCreators.logIn.pending, state => {
				state.isLoading = true
				state.error = undefined
			})
			.addCase(authActionCreators.logIn.fulfilled, (state, action) => {
				state.isLoading = false
				state.isLoaded = true
				state.loggedIn = true
				state.userDetails = action.payload.userDetails
				state.userPermission = action.payload.userPermission
				// state.auth = action.payload
			})
			// .addCase(authActionCreators.logIn.rejected, (state, action) => {
			// 	state.error = action.payload
			// 	// console.log(action)
			// })
			.addCase(authActionCreators.logOut.fulfilled, () => initialState)
			.addCase(authActionCreators.getUserDetails.fulfilled, (state, action) => {
				state.userDetails = action.payload.userDetails
				state.userPermission = action.payload.userPermission
			})
		// .addCase(authActionCreators.getUserPermissions.fulfilled, (state, action) => {
		// 	state.userPermission = action.payload
		// })
	},
})

export const { setLogInState } = authSlice.actions

export default authSlice.reducer

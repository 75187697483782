import {
	RefLinkDetailsForForm,
	RefLinkDetailsForm,
	ReferralLinkDetailsRequestBody,
} from '../../types/referralLinksTypes'
import { useForm } from 'react-hook-form'
import TextFieldForm from '../ui/TextFieldForm'
import { Grid, styled } from '@mui/material'
import { refLinkSchemes } from '../../helpers/resolverScheme/refLinkSchemes'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomTypography from '../ui/CustomTypography'
import { useTheme } from '@mui/system'
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { isEmpty } from 'lodash'

const StyledShareLink = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'flex-start',
	height: '60px',
	'& > p': {
		fontSize: '11px',
		lineHeight: '29px',
		padding: '0 2px',
		whiteSpace: 'nowrap',
		margin: '0',
		color: theme.palette.primary.main,
	},

	'.MuiFormControl-root': {
		marginBottom: '0',
		'.MuiInputBase-root': {
			fontSize: '11px',
			lineHeight: '35px',
			'.MuiInputBase-input': {
				height: '29px',
				padding: 0,
			},
		},
	},
	'@media (max-width: 767px)': {
		flexDirection: 'column',
		marginBottom: '24px',
	},
}))

const StyledCreateRefLinkForm = styled('form')(() => ({
	'.currency-title': {
		paddingLeft: '10px',
		marginTop: '20px',
		marginBottom: '20px',
	},
	'.t-right': {
		'.MuiFormControl-root': {
			'.MuiInputBase-input': {
				textAlign: 'right',
			},
		},
	},
}))

type CreateRefLinkFormProps = {
	refLinkDetailsForm: RefLinkDetailsForForm | null
	setIsDisabledBtn: Dispatch<SetStateAction<boolean>>
	instanceHostUrl: string
	onSave: (dataForm: ReferralLinkDetailsRequestBody) => void
}

const CreateRefLinkForm = ({
	refLinkDetailsForm,
	setIsDisabledBtn,
	onSave,
	instanceHostUrl,
}: CreateRefLinkFormProps) => {
	const theme = useTheme()
	const { handleSubmit, formState, control, setValue, register } =
		useForm<RefLinkDetailsForm>({
			resolver: yupResolver(refLinkSchemes.createRefLinkScheme),
		})

	const setFormValue = useCallback(
		(refLinkDetails: RefLinkDetailsForForm) => {
			setValue('name', refLinkDetails.name || '')
			setValue('key', refLinkDetails.key || '')
			refLinkDetails.fees.forEach((feeEntry, index) => {
				setValue(`fees.${index}.currency`, `${feeEntry.currency.id}`)
				setValue(
					`fees.${index}.fixedValue`,
					feeEntry.fixedValue || feeEntry.fixedValue === 0
						? `${feeEntry.currency.sign}${feeEntry.fixedValue}`
						: '',
				)
				setValue(
					`fees.${index}.percentValue`,
					feeEntry.percentValue || feeEntry.percentValue === 0
						? `${feeEntry.percentValue}%`
						: '',
				)
			})
		},
		[setValue],
	)

	useEffect(() => {
		if (refLinkDetailsForm) {
			setFormValue(refLinkDetailsForm)
		}
	}, [refLinkDetailsForm, setFormValue])

	useEffect(() => {
		if (isEmpty(formState.errors)) {
			setIsDisabledBtn(false)
		} else {
			setIsDisabledBtn(true)
		}
	}, [formState, setIsDisabledBtn])

	const modifyNumberTextField = useCallback((number: string | number) => {
		const modifiedNumberValue = String(number)
			.replace(/[^0-9.]/g, '')
			.replace(/\.$/, '')

		return Number(modifiedNumberValue)
	}, [])

	const onSubmit = (dataForm: RefLinkDetailsForm) => {
		const fees = dataForm.fees.map(feeEntry => ({
			currency: {id: feeEntry.currency},
			value: {
				fixedValue: modifyNumberTextField(feeEntry.fixedValue),
				percentValue: modifyNumberTextField(feeEntry.percentValue),
			},
		}))
		console.log(fees)
		const body = {
			fees,
			name: dataForm.name.trim(),
			key: dataForm.key.trim(),
		}

		onSave(body)
	}

	return (
		<StyledCreateRefLinkForm id="create-ref-link-form" onSubmit={handleSubmit(onSubmit)}>
			<Grid container>
				<Grid item md={5} xs={12}>
					<CustomTypography colorText={theme.palette.warning.dark}>
						Link Name:
					</CustomTypography>
				</Grid>
				<Grid item md={7} xs={12}>
					<div className="t-right">
						<TextFieldForm<RefLinkDetailsForm>
							name="name"
							control={control}
							type="text"
							inputMode="text"
							maxLength={48}
						/>
					</div>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item md={5} xs={12}>
					<CustomTypography colorText={theme.palette.warning.dark}>
						Link URL:
					</CustomTypography>
				</Grid>
				<Grid item md={7} xs={12}>
					<StyledShareLink>
						<p>{`${instanceHostUrl}/login?ref=`}</p>
						<TextFieldForm<RefLinkDetailsForm>
							name="key"
							control={control}
							type="text"
							inputMode="text"
							maxLength={24}
						/>
					</StyledShareLink>
				</Grid>
			</Grid>
			{refLinkDetailsForm?.fees.map((feeItem, index) => (
				<React.Fragment key={feeItem.currency.id}>
					<CustomTypography
						colorText={theme.palette.warning.dark}
						className="currency-title"
					>
						{`${feeItem.currency.id}:`}
					</CustomTypography>
					<input {...register(`fees.${index}.currency`)} hidden />
					<Grid container>
						<Grid item md={5} xs={12}>
							<CustomTypography colorText={theme.palette.warning.dark}>
								Customer Default Spread:
							</CustomTypography>
						</Grid>
						<Grid item md={7} xs={12}>
							<div className="t-right">
								<TextFieldForm<RefLinkDetailsForm>
									name={`fees.${index}.percentValue` as const}
									control={control}
									type="tel"
									inputMode="numeric"
									autoComplete='off'
									suffix="%"
									maxLength={7}
								/>
							</div>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item md={5} xs={12}>
							<CustomTypography colorText={theme.palette.warning.dark}>
								Customer Default Fixed Fee:
							</CustomTypography>
						</Grid>
						<Grid item md={7} xs={12}>
							<div className="t-right">
								<TextFieldForm<RefLinkDetailsForm>
									name={`fees.${index}.fixedValue`}
									control={control}
									type="tel"
									autoComplete='off'
									inputMode="numeric"
									prefix={feeItem.currency.sign}
									maxLength={7}
								/>
							</div>
						</Grid>
					</Grid>
				</React.Fragment>
			))}
		</StyledCreateRefLinkForm>
	)
}

export default CreateRefLinkForm

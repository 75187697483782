const setData = <T>(key: string, data: T) => {
	localStorage.setItem(key, JSON.stringify(data))
}

const getData = (key: string) => {
	const unParsed = localStorage.getItem(key)

	if(unParsed !== null) {
		return JSON.parse(unParsed)
	}
	
	return unParsed
}

const clearAllData = () => {
	Object.keys(localStorage).forEach(key => {
		// if (key !== 'privacy' && key !== 'preset' && key !== 'emailResendTimer') {
		localStorage.removeItem(key)
		// }
	})
}

export const localStorageData = {
	setData,
	getData,
	clearAllData,
}

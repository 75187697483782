import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import {
	StyledCloseButtonModal,
	StyledDialog,
	StyledDialogActions,
	StyledDialogBody,
	StyledDialogContent,
	StyledDialogContentText,
	StyledDialogHeader,
	StyledDialogTitle,
} from './styledModalComponent'
import CancelIcon from '@mui/icons-material/Cancel'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { CustomButton, SecondaryButton } from '../ui/CustomButton'
import { useEffect, useMemo, useState } from 'react'
import { referralLinkActionCreators } from '../../store/actionCreators/referralLinkActionCreators'
import CreateRefLinkForm from '../referralLinks/CreateRefLinkForm'
import { ReferralLinkDetailsRequestBody } from '../../types/referralLinksTypes'
import { removeRefLinkDetails } from '../../store/reducers/referralLinkSlice'
import { FeeDetailsForForm} from '../../types/customersTypes'

type CreateRefLinkModalProps = {
	isOpenDialog: boolean
	onCloseModalClick: ActionCreatorWithoutPayload<'globalModal/closeGlobalModal'>
}

const CreateRefLinkModal = ({
	isOpenDialog,
	onCloseModalClick,
}: CreateRefLinkModalProps) => {
	const dispatch = useAppDispatch()

	const { refLinkDetails, instanceHostUrl } = useAppSelector(state => state.referralLink)

	const [isDisabledBtn, setIsDisabledBtn] = useState(false)

	const refLinkDetailsForm = useMemo(() => {
		let feesArrForm: Array<FeeDetailsForForm> = []

		if (refLinkDetails) {
			feesArrForm = refLinkDetails.fees.map(feeEntry => ({
				currency: feeEntry.currency,
				fixedValue: feeEntry.value.fixedValue,
				percentValue: feeEntry.value.percentValue,
			}))
		}

		if ((refLinkDetails && refLinkDetails.fees.length === 0) || !refLinkDetails) {
			feesArrForm = [
				{
					currency: { id: 'GBP', displayName: 'pound', sign: '£' },
					fixedValue: '',
					percentValue: '',
				},
			]
		}

		return {
			fees: feesArrForm,
			name: refLinkDetails?.name || '',
			key: refLinkDetails?.key || '',
		}
	}, [refLinkDetails])

	useEffect(() => {
		return () => {
			dispatch(removeRefLinkDetails())
		}
	}, [dispatch])

	const closeModal = () => {
		dispatch(onCloseModalClick())
	}

	const onSave = async (dataForm: ReferralLinkDetailsRequestBody) => {
		if (refLinkDetails?.id) {
			const arg = {
				id: refLinkDetails?.id,
				body: dataForm,
			}

			dispatch(referralLinkActionCreators.updateRefLink(arg))
				.unwrap()
				.then(() => closeModal())
				.catch(() => closeModal())
		} else {
			dispatch(referralLinkActionCreators.createRefLink(dataForm))
				.unwrap()
				.then(() => closeModal())
				.catch(() => closeModal())
		}
	}

	return (
		<>
			<StyledDialog open={isOpenDialog} onClose={closeModal}>
				<StyledDialogHeader>
					<StyledDialogTitle>Create/edit invite link</StyledDialogTitle>
					<StyledCloseButtonModal disableRipple onClick={closeModal}>
						Close
						<CancelIcon />
					</StyledCloseButtonModal>
				</StyledDialogHeader>
				<StyledDialogBody>
					<StyledDialogContentText>
						View and edit default fees and rates applied to all customers
					</StyledDialogContentText>
					<StyledDialogContent>
						<Paper elevation={0} className="user-info-action">
							<Grid container>
								<Grid item xs={12}>
									<CreateRefLinkForm
										refLinkDetailsForm={refLinkDetailsForm}
										setIsDisabledBtn={setIsDisabledBtn}
										instanceHostUrl={instanceHostUrl}
										onSave={onSave}
									/>
								</Grid>
							</Grid>
						</Paper>
					</StyledDialogContent>
					<StyledDialogActions sx={{ justifyContent: 'space-between' }}>
						<SecondaryButton type="button" onClick={closeModal}>
							Cancel
						</SecondaryButton>
						<CustomButton
							type="submit"
							disabled={isDisabledBtn}
							form="create-ref-link-form"
						>
							Save
						</CustomButton>
					</StyledDialogActions>
				</StyledDialogBody>
			</StyledDialog>
		</>
	)
}

export default CreateRefLinkModal

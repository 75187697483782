import { AlertColor } from '@mui/material/Alert'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type NotificationState = {
	open: boolean
	type: AlertColor
	message: string
	errorCode?: string
}

const initialState: NotificationState = {
	open: false,
	type: 'info',
	message: '',
}

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		addNotification: (state, action: PayloadAction<NotificationState>) => {
			state.open = action.payload.open
			state.type = action.payload.type
			state.message = action.payload.message
			state.errorCode = action.payload.errorCode
		},
		clearNotification: () => initialState,
	},
})

export const { addNotification, clearNotification } = notificationSlice.actions

export default notificationSlice.reducer

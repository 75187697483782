import { createAsyncThunk } from '@reduxjs/toolkit'
import { referralLinksServices } from '../../services/referralLinksServices'
import {
	ReferralLinkDetailsRequestBody,
	UpdateDefaultRewardFeeListRequestBody,
	UpdateRefLinkDetailsRequest,
} from '../../types/referralLinksTypes'
import { addNotification } from '../reducers/notificationSlice'
import { AlertColor } from '@mui/material'
import { CustomErrorResponse } from '../../types/axiosTypes'
import { RootState } from '..'

const getReferralLinkList = createAsyncThunk(
	'referralLink/getReferralLinkList',
	async (searchValue: string, { rejectWithValue }) => {
		try {
			const response = await referralLinksServices.getReferralLinkList(searchValue)

			return response
		} catch (e) {
			return rejectWithValue('test')
		}
	},
)

const getKWLInstanceInfo = createAsyncThunk(
	'referralLink/getKWLInstanceInfo',
	async (_, { rejectWithValue }) => {
		try {
			const response = await referralLinksServices.getKWLInstanceInfo()
			return response
		} catch (e) {
			return rejectWithValue('Error')
		}
	},
)

const createRefLink = createAsyncThunk(
	'referralLink/createRefLink',
	async (
		arg: ReferralLinkDetailsRequestBody,
		{ rejectWithValue, getState, dispatch },
	) => {
		try {
			await referralLinksServices.createReferralLink(arg)

			const state = getState() as RootState

			const listResponse = await referralLinksServices.getReferralLinkList(
				state.referralLink.searchValue,
			)
			const notificationData = {
				open: true,
				type: 'success' as AlertColor,
				message: 'Successfully referral link created',
			}

			dispatch(addNotification(notificationData))

			return listResponse
		} catch (e) {
			const err = e as CustomErrorResponse
			const notificationData = {
				open: true,
				type: 'error' as AlertColor,
				message: err.message,
				errorCode: err.errorCode,
			}

			dispatch(addNotification(notificationData))

			return rejectWithValue(err)
		}
	},
)

const updateRefLink = createAsyncThunk(
	'referralLink/updateRefLink',
	async (arg: UpdateRefLinkDetailsRequest, { rejectWithValue, getState, dispatch }) => {
		const { id, body } = arg

		try {
			await referralLinksServices.updateReferralLink(id, body)

			const state = getState() as RootState

			const listResponse = await referralLinksServices.getReferralLinkList(
				state.referralLink.searchValue,
			)
			const notificationData = {
				open: true,
				type: 'success' as AlertColor,
				message: 'Successfully referral link updated',
			}

			dispatch(addNotification(notificationData))

			return listResponse
		} catch (e) {
			const err = e as CustomErrorResponse
			const notificationData = {
				open: true,
				type: 'error' as AlertColor,
				message: err.message,
				errorCode: err.errorCode,
			}

			dispatch(addNotification(notificationData))

			return rejectWithValue('Error')
		}
	},
)

const deleteRefLink = createAsyncThunk(
	'referralLink/deleteRefLink',
	async (id: number, { rejectWithValue, dispatch }) => {
		try {
			const response = await referralLinksServices.deleteReferralLink(id)

			const notificationData = {
				open: true,
				type: 'success' as AlertColor,
				message: 'Deleted successfully',
			}

			dispatch(addNotification(notificationData))

			return response
		} catch (e) {
			const err = e as CustomErrorResponse
			const notificationData = {
				open: true,
				type: 'error' as AlertColor,
				message: err.message,
				errorCode: err.errorCode,
			}

			dispatch(addNotification(notificationData))
			return rejectWithValue(err)
		}
	},
)

const getRefLinkDetailsById = createAsyncThunk(
	'referralLink/getRefLinkDetailsById',
	async (refLinkId: number, { rejectWithValue, dispatch }) => {
		try {
			const response = await referralLinksServices.getRefLinkDetailsById(refLinkId)

			return response
		} catch (e) {
			const err = e as CustomErrorResponse
			const notificationData = {
				open: true,
				type: 'error' as AlertColor,
				message: err.message,
				errorCode: err.errorCode,
			}

			dispatch(addNotification(notificationData))
			return rejectWithValue('Error')
		}
	},
)

const getDefaultRewardFeeList = createAsyncThunk(
	'referralLink/getDefaultRewardFeeList',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const response = await referralLinksServices.getDefaultRewardFeeList()

			return response
		} catch (e) {
			const err = e as CustomErrorResponse
			const notificationData = {
				open: true,
				type: 'error' as AlertColor,
				message: err.message,
				errorCode: err.errorCode,
			}

			dispatch(addNotification(notificationData))

			return rejectWithValue('Error')
		}
	},
)

const updateDefaultRewardFeeList = createAsyncThunk(
	'referralLink/updateDefaultRewardFeeList',
	async (body: UpdateDefaultRewardFeeListRequestBody, { rejectWithValue, dispatch }) => {
		try {
			const response = await referralLinksServices.updateDefaultRewardFeeList(body)

			const notificationData = {
				open: true,
				type: 'success' as AlertColor,
				message: 'Default Fees were successfully updated',
			}

			dispatch(addNotification(notificationData))

			return response
		} catch (e) {
			const err = e as CustomErrorResponse
			const notificationData = {
				open: true,
				type: 'error' as AlertColor,
				message: err.message,
				errorCode: err.errorCode,
			}

			dispatch(addNotification(notificationData))
			return rejectWithValue(err)
		}
	},
)

export const referralLinkActionCreators = {
	getReferralLinkList,
	getKWLInstanceInfo,
	createRefLink,
	updateRefLink,
	deleteRefLink,
	getRefLinkDetailsById,
	getDefaultRewardFeeList,
	updateDefaultRewardFeeList,
}

import { Autocomplete, Chip, TextField, styled } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { customersActionCreators } from '../../store/actionCreators/customersActionCreators'
import { InviteLinkForFilter } from '../../types/customersTypes'
import { useCallback, useEffect } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import { AutocompleteFieldValue } from '../../types/commonTypes'

const StyledAutocomplete = styled('div')({
	marginBottom: '20px',
	'.MuiChip-label': {
		// maxWidth: '230px',
	},
})

type MultipleSelectFormProps<T extends FieldValues> = UseControllerProps<T> & {
	label: string
	options: Array<AutocompleteFieldValue>
	// type: 'text' | 'tel' | 'password'
	// inputMode: 'text' | 'numeric'
	// prefix?: string
	// suffix?: string
	// disabled?: boolean
	// maxLength?: number
}

const MultipleSelectForm = <T extends FieldValues>({
	label,
	name,
	control,
	options,
}: MultipleSelectFormProps<T>) => {
	const dispatch = useAppDispatch()
	const { inviteLinkList } = useAppSelector(state => state.customers.filterList)
	const getInviteListForFilterLoadState = useAppSelector(
		state => state.customers.getInviteListForFilterLoadState,
	)

	const {
		field: { onChange, onBlur, value, ref },
	} = useController({
		name,
		control,
	})

	// const onChangeField = useCallback((item: Array<string | AutocompleteFieldValue>) => {
	//   console.log(item)

	//   onChange(item)
	// },
	// [onChange])

	return (
		<StyledAutocomplete>
			<Autocomplete
				fullWidth
				multiple
				className="autocomplete"
				options={options}
				value={typeof value === 'undefined' ? [] : value}
				getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
				renderOption={(props, option) => {
					return (
						<li {...props} key={option.id}>
							{option.name}
						</li>
					)
				}}
				// filterOptions={(options) => options}
				isOptionEqualToValue={(
					option: AutocompleteFieldValue,
					value: AutocompleteFieldValue,
				) => option.id === value.id}
				filterSelectedOptions
				// loading={getInviteListForFilterLoadState.isLoading}
				freeSolo
				onChange={(event, item) => onChange(item)}
				renderInput={params => <TextField {...params} variant="standard" label={label} />}
			/>
		</StyledAutocomplete>
	)
}

export default MultipleSelectForm

import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { authSchemes } from '../../helpers/resolverScheme/authSchemes'
import { styled, useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import TextFieldForm from '../ui/TextFieldForm'
import { StyledCustomButton } from '../ui/CustomButton'
import { Login } from '../../types/authTypes'
import { useAppDispatch } from '../../hooks/redux'
import { authActionCreators } from '../../store/actionCreators/authActionCreators'

const StyledLoginFormButton = styled(StyledCustomButton)({
	marginTop: '20px',
	height: '35px',
})

const StyledLoginForm = styled('form')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	width: '300px',
})

const LoginForm = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const theme = useTheme()
	const {
		handleSubmit,
		control,
	} = useForm<Login>({
		resolver: yupResolver(authSchemes.loginScheme),
	})
	const onSubmit = (dataForm: Login) => {

		dispatch(authActionCreators.logIn({ dataForm, navigate }))
	}


	return (
		<Paper
			elevation={4}
			sx={{
				padding: '30px',
				backgroundColor: theme.palette.warning.main,
				borderRadius: '10px',
			}}
		>
			<StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
				<TextFieldForm<Login>
					name="email"
					control={control}
					label="Login"
					type="text"
					inputMode="text"
				/>
				<TextFieldForm<Login>
					name="password"
					control={control}
					label="Password"
					type='password'
					inputMode="text"
				/>
				<StyledLoginFormButton type="submit">Login</StyledLoginFormButton>
			</StyledLoginForm>
		</Paper>
	)
}

export default LoginForm

import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { styled } from '@mui/material/styles'
import CancelIcon from '@mui/icons-material/Cancel'
import {
	StyledCloseButtonModal,
	StyledDialog,
	StyledDialogActions,
	StyledDialogBody,
	StyledDialogContent,
	StyledDialogContentText,
	StyledDialogHeader,
	StyledDialogTitle,
} from './styledModalComponent'
import { CustomButton } from '../ui/CustomButton'
import { useCallback } from 'react'
import { referralLinkActionCreators } from '../../store/actionCreators/referralLinkActionCreators'
import { removeRefLinkDetails } from '../../store/reducers/referralLinkSlice'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
	width: '300px',
})

type DialogModalProps = {
	isOpenDialog: boolean
	onCloseModalClick: ActionCreatorWithoutPayload<'globalModal/closeGlobalModal'>
}

const DeleteRefLinkModal = ({ isOpenDialog, onCloseModalClick }: DialogModalProps) => {
	const dispatch = useAppDispatch()
	const { id } = useAppSelector(state => state.globalModal)

	const closeModal = useCallback(() => {
		dispatch(onCloseModalClick())
		dispatch(removeRefLinkDetails())
	}, [dispatch, onCloseModalClick])

	const deleteLink = useCallback(() => {
		id && dispatch(referralLinkActionCreators.deleteRefLink(Number(id)))

		closeModal()
	}, [closeModal, dispatch, id])

	return (
		<StyledDialog open={isOpenDialog} onClose={closeModal}>
			<StyledDialogHeader>
				<StyledDialogModalTitle>Delete Referral Link</StyledDialogModalTitle>
				<StyledCloseButtonModal onClick={closeModal}>
					Close
					<CancelIcon />
				</StyledCloseButtonModal>
			</StyledDialogHeader>
			<StyledDialogBody>
				<StyledDialogContent>
					<StyledDialogContentText>
						Are you sure you want to delete this link?
					</StyledDialogContentText>
				</StyledDialogContent>
				<StyledDialogActions>
					<CustomButton type="button" onClick={closeModal}>
						Cancel
					</CustomButton>
					<CustomButton type="button" onClick={deleteLink}>
						Delete
					</CustomButton>
				</StyledDialogActions>
			</StyledDialogBody>
		</StyledDialog>
	)
}

export default DeleteRefLinkModal

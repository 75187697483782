import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles'
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import { CustomIcon } from '../../../helpers/customIcon'
import IconButton from '@mui/material/IconButton'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import MenuIcon from '@mui/icons-material/Menu'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useMediaQuery } from '@mui/material'
import { useEffect } from 'react'
import MainMenuItem from '../../ui/MainMenu/MainMenuItem'

import GroupIcon from '@mui/icons-material/Group'
import LinkIcon from '@mui/icons-material/Link'
import DownloadIcon from '@mui/icons-material/Download';

const mainMenuConfig = [
	{
		label: 'My Customer',
		path: '/my-customers',
		icon: <GroupIcon />,
	},
	{
		label: 'Referral Links',
		path: '/referral-links',
		icon: <LinkIcon />,
	},
	{
		label: 'Reports',
		path: '/reports',
		icon: <DownloadIcon />,
	},
]

type CustomDrawerProps = {
	isOpenMenu: boolean
	onToggleMenu: () => void
}

const CustomDrawer = ({ isOpenMenu, onToggleMenu }: CustomDrawerProps) => {
	const theme = useTheme()

	const openedMixin = (theme: Theme): CSSObject => ({
		width: '292px',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden',
		backgroundImage: 'none',
	})

	const closedMixin = (theme: Theme): CSSObject => ({
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: '66px',
		[theme.breakpoints.down('sm')]: {
			width: 0,
		},
	})

	const TemporaryDrawer = styled(
		MuiDrawer,
		{},
	)(({ theme, open, variant }) => ({
		width: '292px',
		marginTop: '78px',
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		display: 'none',
		// background: '',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	}))

	const PermanentDrawer = styled(MuiDrawer, {
		// shouldForwardProp: prop => prop !== 'open',
	})(({ theme, open, variant }) => ({
		width: '292px',
		marginTop: '78px',
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		display: 'none',
		'.MuiPaper-root': {
			backgroundColor: theme.palette.background.default,
		},
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
			'& .MuiBox-root': {
				svg: {
					paddingLeft: '8px',
					// background: 'red',
				},
			},
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
			'& .MuiBox-root': {
				svg: {
					paddingLeft: '8px',
					// background: 'green',
				},
				[theme.breakpoints.up('sm')]: {
					svg: {
						paddingLeft: '8px',
						'path:first-of-type': {
							display: 'none',
						},
					},
				},
			},
			'& .MuiPaper-root': {},
		}),
		[theme.breakpoints.up('sm')]: {
			display: 'block',
		},
	}))

	const drawerContent = (
		<>
			<Box
				sx={{
					padding: '22px 15px 12px',
					backgroundColor: theme.palette.background.paper,
				}}
			>
				{theme.palette.mode === 'dark' ? (
					<CustomIcon.DarkLogo />
				) : (
					<CustomIcon.LightLogo />
				)}
			</Box>
			<IconButton
				onClick={onToggleMenu}
				sx={{
					width: '50px',
					padding: '5px 20px',
					margin: '0 7px 0 auto',
					color: theme.palette.text.primary,
				}}
			>
				{isOpenMenu ? <MenuOpenIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
			</IconButton>

			<List>
				{mainMenuConfig.map(menuItem => (
					<MainMenuItem
						key={menuItem.label}
						isOpenMenu={isOpenMenu}
						icon={menuItem.icon}
						text={menuItem.label}
						path={menuItem.path}
					/>
				))}
			</List>
		</>
	)

	const desktopBreakPoint = useMediaQuery(theme.breakpoints.up('sm'))
	useEffect(() => {
		if (desktopBreakPoint && isOpenMenu) {
			// document.body.classList.add('no-scroll')
		}
		if (desktopBreakPoint && !isOpenMenu) {
			// document.body.classList.remove('no-scroll')
		}
	}, [desktopBreakPoint, isOpenMenu])

	return (
		<>
			<TemporaryDrawer
				// variant="permanent"
				open={isOpenMenu}
				variant="temporary"
				onClose={onToggleMenu}
				ModalProps={{
					keepMounted: true,
				}}
			>
				{drawerContent}
			</TemporaryDrawer>
			<PermanentDrawer
				variant="permanent"
				open={isOpenMenu}
				onClose={onToggleMenu}
				ModalProps={{
					keepMounted: true,
				}}
			>
				{drawerContent}
			</PermanentDrawer>
		</>
	)
}

export default CustomDrawer

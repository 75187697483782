import FingerPrint from '../../helpers/fingerPrint'
import { localStorageData } from '../../helpers/localStorage'
import {
	AuthBodyRequest,
	AuthData,
	Login,
	UserDetails,
	UserPermission,
} from '../../types/authTypes'
import { anonymousRequest } from '../anonymousRequest'
import { authorizedRequest } from '../authorizeRequest'

const logIn = async (authDataRequest: Login) => {
	const deviceId = await FingerPrint()
	const path = ['accounts', 'login']
	const body = { ...authDataRequest, deviceId }

	return anonymousRequest<AuthBodyRequest, AuthData>(path, 'POST', body)
}

const logOut = () => {
	const path = ['accounts', 'logout']

	return authorizedRequest<null, null>(path, 'POST')
}

const getUserPermissions = () => {
	const path = ['accounts', 'permissions']

	return authorizedRequest<null, Array<UserPermission>>(path, 'GET')
}

const getUserDetails = () => {
	const path = ['accounts']

	return authorizedRequest<null, UserDetails>(path, 'GET')
}

const refreshToken = () => {
	const { refreshToken } = localStorageData.getData('authData')

	// const path = ['tokens', refreshToken, 'refresh']
	const path = ['accounts', 'tokens', refreshToken, 'refresh']

	return anonymousRequest<null, AuthData>(path, 'POST')
}

export const authServices = {
	logIn,
	logOut,
	getUserPermissions,
	getUserDetails,
	refreshToken,
}

import { Alert, SnackbarCloseReason, AlertTitle, styled } from '@mui/material'

import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { clearNotification } from '../store/reducers/notificationSlice'

import Snackbar from '@mui/material/Snackbar'
import { useEffect, useState } from 'react'

export const Notification = (): JSX.Element => {
	const dispatch = useAppDispatch()
	const notification = useAppSelector(state => state.notification)

	// const [isOpenNotification, setIsOpenNotification] = useState(false)
	// const { clearNotification } = useNotification();

	const handleClose = (_: unknown, reason?: SnackbarCloseReason) => {
		reason !== 'clickaway' && dispatch(clearNotification())
	}

	// useEffect(() => {
	// 	if (notification.open) {
	// 		setIsOpenNotification(true)
	// 	}
	// }, [notification.open])

	// useEffect(() => {
	// 	return () => {
	// 		dispatch(clearNotification())
	// 	}
	// }, [dispatch])

	type StyledAlertProps = {
		type: string
	}

	const StyledAlert = styled(Alert, {
		shouldForwardProp: prop => prop !== 'type',
	})<StyledAlertProps>(({ type }) => {
		return {
			color: '#140A5A',
			...(notification.type === 'error' && {
				backgroundColor: '#FFC2CB',
			}),
			...(notification.type === 'success' && {
				backgroundColor: '#e8f5e9',
			}),
		}
	})

	return (
		<Snackbar
			open={notification.open}
			autoHideDuration={5000}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
		>
			<StyledAlert
				variant="filled"
				onClose={handleClose}
				severity={notification.type}
				type={notification.type}
			>
				<AlertTitle>
					{notification.type === 'error' && `Error ${notification.errorCode}`}
					{notification.type === 'success' && `Success`}
				</AlertTitle>
				{notification.message}
			</StyledAlert>
			{/* <Alert
				variant="filled"
				onClose={handleClose}
				severity={notification.type}
				sx={{
					backgroundColor: notification.type === 'error' ? '#d32f2f' : '#2e7d32',
					color: 'white',
				}}
			>
				<AlertTitle>
					{notification.type === 'error' ? `Error ${notification.errorCode}` : 'Success'}
				</AlertTitle>
				{notification.message}
			</Alert> */}
		</Snackbar>
	)
}

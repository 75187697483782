import { ChangeEvent, useCallback, useRef } from 'react'
import { useController, FieldValues, UseControllerProps } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

export const StyledTextField = styled(TextField)(({ theme }) => ({
	width: '100%',
	color: theme.palette.primary.main,
	marginBottom: '24px',

	'.Mui-focused input': {
		background: 'transparent',
	},
	'input:-webkit-autofill': {
		webkitBoxShadow: '0 0 0px 1000px transparent inset;',
	},
	'input:-webkit-autofill:hover': {
		webkitBoxShadow: '0 0 0 30px #transparent inset !important',
	},
	'input:-webkit-autofill:focus': {
		webkitBoxShadow: '0 0 0 30px #transparent inset !important',
	},
	'input:-webkit-autofill:active': {
		webkitBoxShadow: '0 0 0 30px #transparent inset !important',
	},
	'.MuiFormLabel-root': {
		color: theme.palette.primary.main,
		'&.Mui-focused': {
			color: theme.palette.primary.main,
		},
	},

	'.MuiInputBase-root': {
		color: theme.palette.primary.main,
		'&:after': {
			borderColor: '#A8A8A8',
		},
		'&.Mui-focused': {
			'&:before': {
				borderColor: theme.palette.primary.main,
			},
			'&:after': {
				borderColor: theme.palette.primary.main,
			},
		},
	},
}))

type TextFieldFormProps<T extends FieldValues> = UseControllerProps<T> & {
	label?: string
	type: 'text' | 'tel' | 'password'
	inputMode: 'text' | 'numeric'
	prefix?: string
	suffix?: string
	disabled?: boolean
	maxLength?: number
	autoComplete?: string
}

const TextFieldForm = <T extends FieldValues>({
	name,
	control,
	label,
	type,
	inputMode,
	prefix,
	suffix,
	disabled,
	maxLength,
	autoComplete = 'on',
}: TextFieldFormProps<T>) => {
	const {
		field: { onChange, value, ref },
		fieldState: { error: inputError },
		// formState: { errors, isValid },
	} = useController({
		name,
		control,
	})

	let inputCurrentRef = useRef<HTMLInputElement | null>(null)

	const validateAmount = useCallback(
		(value: string, prefix?: string, suffix?: string) => {
			const amountWithoutSymbols = value.replace(/[^0-9.]/g, '')
			const amountWithoutExtraPoints = amountWithoutSymbols
				.replace(/\./, '#')
				.replace(/\./g, '')
				.replace(/#/, '.')

			let newAmount = ''
			let newInputValue = ''

			const arrayFromAmountValue = amountWithoutExtraPoints.split('')

			if (amountWithoutExtraPoints.startsWith('0.') && arrayFromAmountValue.length > 2) {
				newAmount += '0.'
				for (let i = 2; i < arrayFromAmountValue.length; i++) {
					newAmount += arrayFromAmountValue[i]
				}
			} else {
				if (amountWithoutExtraPoints[0] === '0') {
					newAmount += amountWithoutExtraPoints[0]
					if (amountWithoutExtraPoints[1] === '.') {
						newAmount += amountWithoutExtraPoints[1]
					}
					if (amountWithoutExtraPoints[1] && amountWithoutExtraPoints[1].match(/[1-9]/)) {
						newAmount += '.' + amountWithoutExtraPoints[1]
					}
				}

				if (!amountWithoutExtraPoints[0]) {
					newAmount = ''
				}

				if (amountWithoutExtraPoints[0] === '.') {
					newAmount += '0.'
				}
				if (amountWithoutExtraPoints[0] && amountWithoutExtraPoints[0].match(/[1-9]/)) {
					for (let i = 0; i < arrayFromAmountValue.length; i++) {
						newAmount += arrayFromAmountValue[i]
					}
				}
			}

			if (
				amountWithoutExtraPoints &&
				amountWithoutExtraPoints.match(/([\d]+)\.([\d]+)$/) &&
				!amountWithoutExtraPoints.match(/([\d]+)\.([0])$/)
			) {
				const test = Number(amountWithoutExtraPoints).toFixed(3).slice(0, -1)
				newAmount = String(parseFloat(test))
			}

			if (!amountWithoutExtraPoints) {
				newAmount = ''
			}

			if (prefix) {
				newInputValue = prefix + newAmount
			}
			if (suffix) {
				newInputValue = newAmount + suffix
			}

			onChange(newInputValue)
		},
		[onChange],
	)

	const setCarriagePosition = useCallback((value: string) => {
		if (
			inputCurrentRef.current?.selectionEnd !== null &&
			inputCurrentRef.current !== null &&
			inputCurrentRef.current.selectionEnd === value.length
		) {
			setTimeout(() => {
				if (
					inputCurrentRef.current?.selectionEnd !== null &&
					inputCurrentRef.current !== null
				) {
					inputCurrentRef.current!.selectionEnd =
						inputCurrentRef.current!.selectionEnd - 1
				}
			}, 0)
		}
	}, [])

	const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value

		if (prefix) {
			validateAmount(value, prefix)
		}
		if (suffix) {
			validateAmount(value, undefined, suffix)
			setCarriagePosition(value)
		}

		if (type === 'text' || type === 'password') {
			onChange(value)
		}
	}

	const onInputBlur = () => {
		const value = inputCurrentRef.current?.value
		const valueWithoutSymbol = value?.replace(/[^0-9.]/g, '')
		if (prefix && !valueWithoutSymbol) {
			onChange(`${prefix}0`)
		}
		if (suffix && !valueWithoutSymbol) {
			onChange(`0${suffix}`)
		}
	}

	return (
		<StyledTextField
			color="info"
			variant="standard"
			autoComplete={autoComplete}
			type={type}
			inputMode={inputMode}
			inputRef={event => {
				ref(event)
				inputCurrentRef.current = event
			}}
			onBlur={onInputBlur}
			{...(maxLength && { inputProps: { maxLength: maxLength } })}
			{...(label && { label: label })}
			label={label}
			// id="test"
			// hidden
			value={typeof value === 'undefined' ? '' : value}
			onChange={onInputChange}
			{...(disabled && { disabled })}
			{...(inputError && { error: true, helperText: inputError.message })}
		/>
	)
}

export default TextFieldForm

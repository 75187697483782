import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import ReferralLinkTable from '../../components/referralLinks/referralTable/ReferralLinkTable'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { referralLinkActionCreators } from '../../store/actionCreators/referralLinkActionCreators'
import { FullReferralLinkDetails } from '../../types/referralLinksTypes'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { referralLinksTableConfig } from '../../helpers/tableConfigs/referralLinksTableConfigs'
import {
	FilledInput,
	IconButton,
	InputAdornment,
	styled,
	Typography,
	useTheme,
} from '@mui/material'
import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid'
import { CustomButton } from '../../components/ui/CustomButton'
import { CustomIcon } from '../../helpers/customIcon'
import Stack from '@mui/material/Stack'
import { openGlobalModal } from '../../store/reducers/globalModalSlice'
import debounce from 'lodash/debounce'
import { removeReferralLinkList } from '../../store/reducers/referralLinkSlice'
import { referralLinksServices } from '../../services/referralLinksServices'
import { isKwlMAPermission } from '../../helpers/utils/auth'

var fileDownload = require('js-file-download')

const StyledReferralLinksPage = styled(Box)(({ theme }) => ({
	'.section-title': {
		paddingLeft: '16px',
		marginBottom: '17px',
		marginTop: '17px',
		[theme.breakpoints.down('md')]: {
			fontSize: '27px',
			lineHeight: '40px',
			paddingLeft: '10px',
			marginBottom: '10px',
		},
	},

	'.MuiGrid-item.search-wrap': {
		paddingRight: '16px',
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0,
		},
	},

	'.MuiGrid-root': {
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0',
			width: '100%',
			'& > .MuiGrid-item': {
				paddingLeft: '0',
				'.row-btn': {
					justifyContent: 'flex-start',
					flexWrap: 'wrap',
					gap: '10px',
					button: {
						margin: '0',
					},
				},
			},
		},
	},
	'.action-block': {
		marginLeft: 0,
		marginBottom: '24px',
		width: '100%',
	},

	'.row-btn': {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-between',
		},
	},
	'.search-wrap': {
		// minWidth: '330px',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
			minWidth: '100%',
		},
	},
}))

const Search = styled('div')(({ theme }) => ({
	// maxWidth: '404px',
	width: '100%',
	position: 'relative',
	borderRadius: '10px',
	border: '1px solid transparent',
	backgroundColor: theme.palette.info.main,
	cursor: 'pointer',
	// marginRight: theme.spacing(2),
	marginLeft: 'auto',

	'&:hover': {
		borderColor: theme.palette.text.primary,
	},
	'.MuiInputBase-root': {
		width: '100%',
		borderRadius: '10px',
		// color: theme.palette.primary.light,
		'&:after': {
			display: 'none',
		},
		'&:before': {
			display: 'none',
		},
	},
	'.MuiInputBase-input': {
		padding: '8px 8px 8px 16px!important',
		paddingRight: 'calc(1em + 32px)',
		width: '100%',
	},
	[theme.breakpoints.down('md')]: {
		margin: '0 auto 0 0',
		maxWidth: '100%',
		width: '100%',
	},
}))

const ReferralLinksPage = () => {
	const dispatch = useAppDispatch()
	const theme = useTheme()

	const { items: tableData, hasNextPage } = useAppSelector(
		state => state.referralLink.referralLinkList,
	)
	const { getReferralLinkListLoadState, error } = useAppSelector(
		state => state.referralLink,
	)
	const { userPermission } = useAppSelector(state => state.auth)
	const hasKwlMAPermission = isKwlMAPermission(userPermission)

	const [searchInputValue, setSearchInputValue] = useState('')

	// const pageSize = 15
	// const offset = 0

	const tableConfig = useMemo(() => {
		return referralLinksTableConfig()
	}, [])

	const getRefLinkList = useCallback(() => {
		const trimmedSearchValue = searchInputValue.trim()

		dispatch(referralLinkActionCreators.getReferralLinkList(trimmedSearchValue))
	}, [dispatch, searchInputValue])

	const debounceFunction = useMemo(() => debounce(getRefLinkList, 700), [getRefLinkList])

	const onLoadMore = async () => {
		// const currentOffset = offset + tableData.length
		// const currentParams = getQueryString(
		//   concatenateObjProps({ ...filterList, offset: currentOffset, pageSize })
		// )
		// await dispatch(
		//   worldPayActions.getKwlCardPayments({ queryParams: currentParams, offset: currentOffset })
		// )
	}

	const [sentryRef, { rootRef }] = useInfiniteScroll({
		loading: getReferralLinkListLoadState.isLoading,
		hasNextPage,
		onLoadMore,
		disabled: !!error,
		rootMargin: '0px 0px 400px 0px',
	})

	const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value

		setSearchInputValue(value)
	}, [])

	useEffect(() => {
		debounceFunction()

		return debounceFunction.cancel
	}, [debounceFunction])

	useEffect(() => {
		return () => {
			dispatch(removeReferralLinkList())
		}
	}, [dispatch])

	const onCreateReferralLink = useCallback(async () => {
		await dispatch(referralLinkActionCreators.getKWLInstanceInfo())

		dispatch(openGlobalModal({ variant: 'create-ref-link' }))
	}, [dispatch])

	const clearSearchInput = useCallback(() => {
		setSearchInputValue('')
	}, [])

	const downloadAllReports = useCallback(async () => {
		const result = await referralLinksServices.downloadAllRefLinkReportCsv()

		const file = await result.data
		const header = result.headers['content-disposition'] || ''
		const fileName = header.match(/filename=(.*);/) || []
		const test = fileName.length > 0 && fileName[1]

		await fileDownload(file, test)
	}, [])

	const setDefaultRewardFees = useCallback(() => {
		hasKwlMAPermission &&
			dispatch(openGlobalModal({ variant: 'set-default-reward-fees' }))
	}, [dispatch, hasKwlMAPermission])

	return (
		<StyledReferralLinksPage>
			<Typography variant="h2" className="section-title">
				Referral Links
			</Typography>
			<Grid className="action-block" container spacing={2}>
				<Grid item sm={12} md={8} lg={7}>
					<Stack className="row-btn" spacing={2} direction="row">
						<CustomButton type="button" onClick={onCreateReferralLink}>
							+ Invite New
						</CustomButton>
						<CustomButton type="button" onClick={downloadAllReports}>
							Download All
						</CustomButton>
						{hasKwlMAPermission && (
							<CustomButton type="button" onClick={setDefaultRewardFees}>
								Set Default Fees
							</CustomButton>
						)}
					</Stack>
				</Grid>
				<Grid className="search-wrap" item sm={12} md={4} lg={5}>
					<Search>
						<FilledInput
							// variant="filled"
							placeholder="Search"
							value={searchInputValue}
							onChange={onInputChange}
							endAdornment={
								<InputAdornment position="end">
									<IconButton onClick={clearSearchInput}>
										{theme.palette.mode === 'dark' ? (
											<CustomIcon.DarkCloseSearch />
										) : (
											<CustomIcon.LightCloseSearch />
										)}
									</IconButton>
								</InputAdornment>
							}
						/>
					</Search>
				</Grid>
			</Grid>
			<ReferralLinkTable<FullReferralLinkDetails>
				tableData={tableData}
				sentryRef={sentryRef}
				rootRef={rootRef}
				tableConfig={tableConfig}
				loading={getReferralLinkListLoadState.isLoading}
				// minTableWidth="360px"
				hasNextPage={hasNextPage}
			/>
		</StyledReferralLinksPage>
	)
}

export default ReferralLinksPage

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { customersActionCreators } from '../../store/actionCreators/customersActionCreators'
import { clearFilterStateForMyCustomerTable } from '../../store/reducers/customersSlice'

import format from 'date-fns/format'

import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { DatePicker } from 'antd'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'

import { isKwlMAPermission, isRBZReportPermission } from '../../helpers/utils/auth'
import { FilterStateForReports } from '../../types/reportsTypes'

import MultipleSelector from '../../components/ui/MultipleSelector'
import { CustomButton } from '../../components/ui/CustomButton'
import isArray from 'lodash/isArray'
import { MultiSelectOption } from '../../types/commonTypes'
import { reportsServices } from '../../services/reportsServices'

var fileDownload = require('js-file-download')

const { RangePicker } = DatePicker.generatePicker<Date>(dateFnsGenerateConfig)

type EventValue<DateType> = DateType | null
type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>]

const StyledRangePicker = styled(RangePicker)(({ theme }) => ({
	width: '100%',
	height: '55px',
	marginBottom: '20px',
	background: theme.palette.background.default,
	fontFamily: 'Roboto',
	'.ant-picker-input >input': {
		color: theme.palette.text.primary,
		'&::placeholder': {
			color: theme.palette.text.secondary,
		},
	},
	'.ant-picker-separator, .ant-picker-suffix': {
		color: theme.palette.text.primary,
	},

	'.ant-picker-clear': {
		background: theme.palette.background.default,
		color: theme.palette.text.primary,
		'&:hover': {
			color: theme.palette.text.primary,
		},
	},
}))

const StyledReferralLinksPage = styled(Box)(({ theme }) => ({
	'.section-title': {
		paddingLeft: '16px',
		marginBottom: '17px',
		marginTop: '17px',
		[theme.breakpoints.down('md')]: {
			fontSize: '27px',
			lineHeight: '40px',
			paddingLeft: '10px',
			marginBottom: '10px',
		},
	},
	'.hide-mobile': {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	'.show-mobile': {
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-block',
		},
	},
}))

const ReportsPage = () => {
	const dispatch = useAppDispatch()
	const { userPermission } = useAppSelector(state => state.auth)
	const { inviterList, inviteLinkList } = useAppSelector(
		state => state.customers.filterList,
	)
	const [filterList, setFilterList] = useState<FilterStateForReports>({
		inviteLinkIds: [],
		inviterIds: [],
		startDate: '',
		endDate: '',
	})

	const modifiedInviterListForFilter = useMemo(() => {
		return inviterList.map(item => ({
			id: item.id,
			name: `${item.firstName} ${item.lastName}`,
		}))
	}, [inviterList])

	useEffect(() => {
		dispatch(customersActionCreators.getInviteLinkListForFilter())
		if (isKwlMAPermission(userPermission)) {
			dispatch(customersActionCreators.getInviterListForFilter())
		}
	}, [dispatch, userPermission])

	useEffect(() => {
		return () => {
			dispatch(clearFilterStateForMyCustomerTable())
		}
	}, [dispatch])

	const onChangeDate = useCallback((value: RangeValue<Date> | null) => {
		const startDate = value?.[0] ? `${format(value?.[0], 'yyyy-MM-dd')}T00:00:00Z` : ''
		const endDate = value?.[1] ? `${format(value?.[1], 'yyyy-MM-dd')}T23:59:59Z` : ''

		setFilterList(prevState => ({ ...prevState, startDate, endDate }))
	}, [])

	const onChangeSelector = useCallback((id: string, value: any) => {
		setFilterList(prevState => ({ ...prevState, ...{ [id]: value } }))
	}, [])

	const downloadTransactionReports = useCallback(
		async (queryParams: Record<string, string | number | Array<string | number>>) => {
			const result = await reportsServices.downloadTransactionReportCsv(queryParams)

			const file = await result.data
			const header = result.headers['content-disposition'] || ''
			const fileName = header.match(/filename=(.*);/) || []
			const test = fileName.length > 0 && fileName[1]

			await fileDownload(file, test)
		},
		[],
	)

	const onClickDownloadRBZReport = useCallback(() => {
		let queryParams = {}
		for (const prop in filterList) {
			if (prop === 'startDate' && filterList[prop]) {
				queryParams = { ...queryParams, 'dateRange.startDate': filterList[prop] }
			}
			if (prop === 'endDate' && filterList[prop]) {
				queryParams = { ...queryParams, 'dateRange.endDate': filterList[prop] }
			}
		}

		reportsServices.downloadRBZReportCSV(queryParams)
	}, [filterList])

	const getTransactionReport = useCallback(() => {
		let queryParams = {}
		// console.log(filterList)
		for (const prop in filterList) {
			if (isArray(filterList[prop])) {
				const valueArr = filterList[prop].map((item: MultiSelectOption) => item.id)
				queryParams = { ...queryParams, [prop]: valueArr }
			}
			if (prop === 'startDate' && filterList[prop]) {
				queryParams = { ...queryParams, 'dateRange.startDate': filterList[prop] }
			}
			if (prop === 'endDate' && filterList[prop]) {
				queryParams = { ...queryParams, 'dateRange.endDate': filterList[prop] }
			}
		}

		downloadTransactionReports(queryParams)
	}, [downloadTransactionReports, filterList])

	const onClickDownloadCustomerReport = useCallback(() => {
		let queryParams = {}

		if (filterList.startDate) {
			queryParams = { ...queryParams, 'dateRange.startDate': filterList.startDate }
		}
		if (filterList.endDate) {
			queryParams = { ...queryParams, 'dateRange.endDate': filterList.endDate }
		}
		if (filterList.inviterIds) {
			const valueArr = filterList.inviterIds.map((item: MultiSelectOption) => item.id)
			queryParams = { ...queryParams, inviterIds: valueArr }
		}

		reportsServices.downloadCustomerReportCsv(queryParams)
	}, [filterList])

	return (
		<StyledReferralLinksPage>
			<Typography variant="h2" className="section-title">
				Reports
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={5} lg={5}>
					<StyledRangePicker onChange={onChangeDate} />
				</Grid>
				<Grid item xs={12} sm={3.5} lg={3.5}>
					<MultipleSelector
						label="Invite Links"
						id="inviteLinkIds"
						options={inviteLinkList}
						onMainChange={onChangeSelector}
					/>
				</Grid>
				<Grid item xs={12} sm={3.5} lg={3.5}>
					{isKwlMAPermission(userPermission) && (
						<MultipleSelector
							label="Agents"
							id="inviterIds"
							options={modifiedInviterListForFilter}
							onMainChange={onChangeSelector}
						/>
					)}
				</Grid>
				<Grid container item xs={12} sm={7} lg={5} wrap="wrap" spacing={1}>
					<Grid item xs={12} sm={6} lg={6}>
						<CustomButton
							type="button"
							onClick={getTransactionReport}
							// className="show-mobile"
						>
							Transaction Report
						</CustomButton>
					</Grid>
					{isRBZReportPermission(userPermission) && (
						<Grid item xs={12} sm={6} lg={6}>
							<CustomButton
								type="button"
								onClick={onClickDownloadRBZReport}
								// className="show-mobile"
							>
								RBZ Report
							</CustomButton>
						</Grid>
					)}
					<Grid item xs={12} sm={6} lg={6}>
						<CustomButton
							type="button"
							onClick={onClickDownloadCustomerReport}
							// className="show-mobile"
						>
							Customer Report
						</CustomButton>
					</Grid>
				</Grid>
			</Grid>
		</StyledReferralLinksPage>
	)
}

export default ReportsPage

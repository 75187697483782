import { SetDefaultRewardFeesFormType } from '../../../types/referralLinksTypes'
import { useForm } from 'react-hook-form'
import TextFieldForm from '../../ui/TextFieldForm'
import { Grid, styled } from '@mui/material'
import { refLinkSchemes } from '../../../helpers/resolverScheme/refLinkSchemes'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomTypography from '../../ui/CustomTypography'
import { useTheme } from '@mui/system'
import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { isEmpty } from 'lodash'
import {
	Currency,
	FeeDetailsResponse,
	FeeDetailsRequest,
} from '../../../types/commonTypes'

const StyledSetDefaultRewardFeesForm = styled('form')(() => ({
	'.currency-title': {
		// paddingLeft: '10px',
		marginTop: '10px',
		marginBottom: '10px',
		'&:first-of-type': {
			marginTop: 0,
		},
	},
	'.t-right': {
		'.MuiFormControl-root': {
			marginBottom: 0,
			'.MuiInputBase-input': {
				textAlign: 'right',
			},
		},
	},
	'.MuiTypography-root': {
		lineHeight: '36px',
	},
}))

type SetDefaultRewardFeesFormProps = {
	defaultRewardFeeList: Array<FeeDetailsResponse>
	setIsDisabledBtn: Dispatch<SetStateAction<boolean>>
	currencies: Array<Currency>
	onSave: (dataForm: Array<FeeDetailsRequest>) => void
}

const SetDefaultRewardFeesForm = ({
	defaultRewardFeeList,
	setIsDisabledBtn,
	currencies,
	onSave,
}: SetDefaultRewardFeesFormProps) => {
	const theme = useTheme()
	const { handleSubmit, formState, control, setValue } =
		useForm<SetDefaultRewardFeesFormType>({
			resolver: yupResolver(refLinkSchemes.setDefaultRewardFeesScheme(currencies)),
		})

	const setFormValue = useCallback(() => {
		defaultRewardFeeList.forEach(rewardFeeItem => {
			setValue(
				`${rewardFeeItem.currency.id}.fixedValue`,
				`${rewardFeeItem.currency.sign}${rewardFeeItem.value.fixedValue}`,
			)
			setValue(
				`${rewardFeeItem.currency.id}.percentValue`,
				`${rewardFeeItem.value.percentValue}%`,
			)
		})
	}, [defaultRewardFeeList, setValue])

	useEffect(() => {
		setFormValue()
	}, [setFormValue])

	useEffect(() => {
		if (isEmpty(formState.errors)) {
			setIsDisabledBtn(false)
		} else {
			setIsDisabledBtn(true)
		}
	}, [formState, setIsDisabledBtn])

	const modifyNumberTextField = useCallback((number: string | number) => {
		const modifiedNumberValue = String(number)
			.replace(/[^0-9.]/g, '')
			.replace(/\.$/, '')

		return Number(modifiedNumberValue)
	}, [])

	const onSubmit = (dataForm: SetDefaultRewardFeesFormType) => {
		let fees: Array<FeeDetailsRequest> = []

		for (const key in dataForm) {
			const feeForCurrentCurrency = [
				{
					currency: {id: key},
					value: {
						fixedValue: modifyNumberTextField(dataForm[key].fixedValue),
						percentValue: modifyNumberTextField(dataForm[key].percentValue),
					},
				},
			]

			fees = [...fees, ...feeForCurrentCurrency]
		}

		onSave(fees)
	}

	return (
		<StyledSetDefaultRewardFeesForm
			id="set-default-reward-fees-form"
			onSubmit={handleSubmit(onSubmit)}
		>
			{defaultRewardFeeList.map(rewardFeeItem => (
				<React.Fragment key={rewardFeeItem.currency.id}>
					<CustomTypography
						colorText={theme.palette.warning.dark}
						className="currency-title"
					>
						{`${rewardFeeItem.currency.id}:`}
					</CustomTypography>
					<Grid container>
						<Grid item md={7} xs={12}>
							<CustomTypography colorText={theme.palette.warning.dark}>
								Customer Default Spread:
							</CustomTypography>
						</Grid>
						<Grid item md={5} xs={12}>
							<div className="t-right">
								<TextFieldForm<SetDefaultRewardFeesFormType>
									name={`${rewardFeeItem.currency.id}.percentValue`}
									control={control}
									type="tel"
									inputMode="numeric"
									autoComplete="off"
									suffix="%"
									maxLength={7}
								/>
							</div>
						</Grid>
						<Grid item md={7} xs={12}>
							<CustomTypography colorText={theme.palette.warning.dark}>
								Customer Default Fixed Fee:
							</CustomTypography>
						</Grid>
						<Grid item md={5} xs={12}>
							<div className="t-right">
								<TextFieldForm<SetDefaultRewardFeesFormType>
									name={`${rewardFeeItem.currency.id}.fixedValue`}
									control={control}
									type="tel"
									autoComplete="off"
									inputMode="numeric"
									prefix={rewardFeeItem.currency.sign}
									maxLength={7}
								/>
							</div>
						</Grid>
					</Grid>
				</React.Fragment>
			))}
		</StyledSetDefaultRewardFeesForm>
	)
}

export default SetDefaultRewardFeesForm

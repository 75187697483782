import { authorizedRequest, getFile } from '../api/authorizeRequest'
import {
	GetDefaultRewardFeeListResponse,
	GetReferralLinksResponse,
	KWLInstanceInfo,
	RefLinkDetails,
	ReferralLinkDetailsRequestBody,
	UpdateDefaultRewardFeeListRequestBody,
} from '../types/referralLinksTypes'

const getReferralLinkList = (searchValue: string) => {
	const path = ['invite-links']

	if (searchValue) {
		const query = {
			query: searchValue,
		}
		return authorizedRequest<null, GetReferralLinksResponse>(path, 'GET', null, query)
	} else {
		return authorizedRequest<null, GetReferralLinksResponse>(path, 'GET')
	}
}

const createReferralLink = (body: ReferralLinkDetailsRequestBody) => {
	const path = ['invite-links']

	return authorizedRequest<ReferralLinkDetailsRequestBody, null>(path, 'POST', body)
}

const updateReferralLink = (
	inviteLinkId: number,
	body: ReferralLinkDetailsRequestBody,
) => {
	const path = ['invite-links', String(inviteLinkId)]

	return authorizedRequest<ReferralLinkDetailsRequestBody, null>(path, 'PUT', body)
}

const deleteReferralLink = (inviteLinkId: number) => {
	const path = ['invite-links', String(inviteLinkId)]

	return authorizedRequest<null, null>(path, 'DELETE')
}

const getKWLInstanceInfo = () => {
	const path = ['instance', 'info']

	return authorizedRequest<null, KWLInstanceInfo>(path, 'GET')
}

const downloadAllRefLinkReportCsv = () => {
	const path = ['invite-links', 'report']

	return getFile(path)
}

const downloadRefLinkReportByIds = (ids: Array<string | number>) => {
	const path = ['invite-links', 'report']
	const query = { InviteLinkIds: ids }

	return getFile(path, query)
}

const getRefLinkDetailsById = (refLinkId: number) => {
	const path = ['invite-links', String(refLinkId)]

	return authorizedRequest<null, RefLinkDetails>(path)
}

const getDefaultRewardFeeList = () => {
	const path = ['instance', 'fees', 'agent-reward']

	return authorizedRequest<null, GetDefaultRewardFeeListResponse>(path)
}

const updateDefaultRewardFeeList = (body: UpdateDefaultRewardFeeListRequestBody) => {
	const path = ['instance', 'fees', 'agent-reward']

	return authorizedRequest<UpdateDefaultRewardFeeListRequestBody, null>(path, 'put', body)
}

export const referralLinksServices = {
	getReferralLinkList,
	updateReferralLink,
	deleteReferralLink,
	createReferralLink,
	getKWLInstanceInfo,
	downloadAllRefLinkReportCsv,
	downloadRefLinkReportByIds,
	getRefLinkDetailsById,
	getDefaultRewardFeeList,
	updateDefaultRewardFeeList,
}

import { styled } from '@mui/material'
import { TableConfig } from '../../../types/tableTypes'
import { StyledRowCell } from './TableRow'

const StyledLazyCustomersTableHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	height: '49px',
	backgroundColor: theme.palette.background.default,
	minWidth: 'fit-content',
	width: '100%',

	'.table-header-item': {
		height: '49px',
		lineHeight: '49px',
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.main,
		fontWeight: '700',
		fontSize: '16px',
		'&:first-of-type': {
			borderTopLeftRadius: '10px',
		},
		'&:last-of-type': {
			borderTopRightRadius: '10px',
		},
	},
	'@media (max-width: 767px)': {
		height: '40px',

		'.table-header-item': {
			height: '40px',
			fontSize: '14px',
			lineHeight: '40px',			
		},
	},
}))

type LazyCustomersTableHeaderProps<T> = {
	tableConfig: TableConfig<T>
}

const LazyCustomersTableHeader = <T,>({
	tableConfig,
}: LazyCustomersTableHeaderProps<T>) => {
	return (
		<StyledLazyCustomersTableHeader className="table-header">
			{tableConfig.columns.map(column => (
				<StyledRowCell className="table-header-item" key={column.id} width={column.width}>
					{column.header}
				</StyledRowCell>
			))}
		</StyledLazyCustomersTableHeader>
	)
}

export default LazyCustomersTableHeader

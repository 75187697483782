import { memo, useCallback, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Popover from '@mui/material/Popover'
import { useTheme } from '@mui/material/styles'

type CopyButtonProps = {
	url: string
}

const CopyButton = ({ url }: CopyButtonProps) => {
	const theme = useTheme()

	const [showToolTip, setShowToolTip] = useState(false)
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

	const onCopyClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			setShowToolTip(true)
			setAnchorEl(event.currentTarget)

			navigator.clipboard.writeText(url ?? '')
			window.setTimeout(() => {
				setShowToolTip(false)
				setAnchorEl(null)
			}, 1000)
		},
		[url],
	)

	return (
		<IconButton
			sx={{
				padding: '0 0 0 10px',
				lineHeight: '38px',
				height: '38px',
				color: theme.palette.text.secondary,
			}}
			disableRipple
			onClick={onCopyClick}
		>
			<ContentCopyIcon />
			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none',
					'.MuiPopover-paper': { padding: '5px', fontSize: '12px' },
				}}
				open={showToolTip}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				Copied to the clipboard
			</Popover>
		</IconButton>
	)
}

export default memo(CopyButton)

import { PaletteMode } from '@mui/material'

export const getTheme = (mode: PaletteMode) => ({
	palette: {
		mode,
		...(mode === 'light'
			? {
					// palette values for light mode
					primary: {
						main: '#3171D8', // main blue / white
						light: '#3171D8', // main blue / blue
						dark: '#E6E6E6', // table header / dark
						contrastText: '#AFAFAF ', //close btn #AFAFAF = #fff
					},
					secondary: {
						light: '#6AD496', // green
						main: '#EB5757', // red
						dark: '#EFF6FF', // table item odd / dark
					  },
					info: {
						light: '#2D82F5', // btn /
						main: '#EFEFEF', // search input
						dark: '#082280', // menu item / grey
						contrastText: '#ffffff', // pop-up header bg / dark
					},
					warning: { // pop=up
						main: '#EEFDFF', // content bg light //
						dark: '#707070'// content title light //
					},
					success: {// pop=up
						main: '#F6F6F6', //#F6F6F6
					},
					text: {
						primary: '#140A5A', // title color / white
						secondary: '#3171D8', //
					},
					background: {
						default: '#ffffff', // main bg / dark
						paper: '#ffffff', // header bg / grey
					},
					// error: {
					// 	main: '', // 
					// },
					divider: '#353535', // divider
					common: {
						black: '#000000',
						white: '#fffff'
					},
					action: {
						hover: '#CCE2FF' // table row hover
					}
			  }
			: {
					// palette values for dark mode
					primary: {
						main: '#fff', // main blue / white
						light: '#3171D8', // main blue / blue
						dark: '#1E1F32', // table header / dark
						contrastText: '#fff ', //close btn #AFAFAF = #fff
					},
					secondary: {
						light: '#6AD496', // green
						main: '#EB5757', // red
						dark: '#2A3144', // table item odd / dark
						contrastText: '#3C4661' // table item even  / dark
					  },
					info: {
						light: '#2D82F5', // btn /
						main: '#3F4963', // search input
						dark: '#2A3144', // menu item / grey
						contrastText: ' #44506F',  // pop-up header bg / dark
					},
					text: {
						primary: '#fff', // title color / white
						secondary: '#fff', // 
					},
					background: {
						default: '#161725', // main bg / dark
						paper: '#1E1F32', // header bg / grey
					},
					divider: '#353535',
					common: {
						black: '#000',
						white: '#fff'
					},
					success: {// pop=up
						main: '#2A3144', //#F6F6F6 - #2A3144
					},
					action: {
						hover: '#99a4b3' // table row hover
					},
					warning: { // pop=up
						main: '#2A3144', // content bg light // dark
						dark: '#fff'// content title light //
					},
					// error: {
					// 	main: '',  
					// },
			  }),
	},
	breakpoints: {
		values: {
		  xs: 0,
		  sm: 768,
		  md: 900,
		  lg: 1200,
		  xl: 1536,
		},
	  },
	typography: {
		fontSize: 16,
		fontFamily: 'Roboto,sans-serif',

		h1: {
			fontSize: '2.375rem',
			fontWeight: 500,
			lineHeight: '2.875rem',
		},
		h2: {
			fontFamily: 'Roboto,sans-serif',
			fontStyle: 'normal',
			fontWeight: '400',
			fontSize: '34px',
			lineHeight: '40px',
		}
	}

})

import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { NavigateFunction } from 'react-router-dom'
import { authServices } from '../../api/services/authServices'
import { localStorageData } from '../../helpers/localStorage'
import { AuthData, Login, LoginRequestArg } from '../../types/authTypes'
import { CustomErrorResponse } from '../../types/axiosTypes'
import { addNotification } from '../reducers/notificationSlice'
import { AlertColor } from '@mui/material/Alert'
import { useHistory } from '../../hooks/redux'

const logIn = createAsyncThunk(
	'auth/logIn',
	async (arg: LoginRequestArg, { rejectWithValue, dispatch }) => {
		const { dataForm, navigate } = arg
		try {
			const response = await authServices.logIn(dataForm)

			localStorageData.setData<AuthData>('authData', response)

			const userPermissionsResponse = await authServices.getUserPermissions()
			const userDetailsResponse = await authServices.getUserDetails()

			navigate('/my-customers')
			// const notificationData = {
			// 	open: true,
			// 	type: 'success' as AlertColor,
			// 	message: 'Login',
			// }

			// dispatch(addNotification(notificationData))

			return { userDetails: userDetailsResponse, userPermission: userPermissionsResponse }
		} catch (e) {
			const err = e as CustomErrorResponse
			const notificationData = {
				open: true,
				type: 'error' as AlertColor,
				message: err.message,
				errorCode: err.errorCode,
			}

			dispatch(addNotification(notificationData))
			return rejectWithValue(err)
			// console.log(err)
			// return rejectWithValue(err as CustomErrorResponse)
			// return setError(err.message)
		}
	},
)

const logOut = createAsyncThunk(
	'auth/logOut',
	async (arg: { navigate: NavigateFunction }, { rejectWithValue }) => {
		const { navigate } = arg
		try {
			const response = await authServices.logOut()

			localStorageData.clearAllData()

			// navigate('/login')
			window.location.href = '/login'

			return response
		} catch (e) {}
	},
)

const getUserDetails = createAsyncThunk(
	'auth/getUserDetails',
	async (_, { rejectWithValue }) => {
		try {
			const userPermissionsResponse = await authServices.getUserPermissions()
			const userDetailsResponse = await authServices.getUserDetails()

			return { userDetails: userDetailsResponse, userPermission: userPermissionsResponse }
		} catch (e) {
			return rejectWithValue('test')
		}
	},
)

// const getUserDetails = createAsyncThunk(
// 	'auth/getUserDetails',
// 	async (_, { rejectWithValue }) => {
// 		try {
// 			const response = await authServices.getUserDetails()

// 			return response
// 		} catch (e) {
// 			return rejectWithValue('test')
// 		}
// 	},
// )

export const authActionCreators = {
	logIn,
	logOut,
	// getUserPermissions,
	getUserDetails,
}

import * as yup from 'yup'
import { Currency } from '../../types/commonTypes'

const createRefLinkScheme = yup.object({
	name: yup
		.string()
		.typeError('Required field')
		.trim()
		.matches(/^[\s]*[a-zA-Z0-9-_`'\s]*[\s]*$/, 'Please, use (a-z, A-Z, 0-9, -, _,)')
		.required('Required field'),
	key: yup
		.string()
		.typeError('Required field')
		.trim()
		.matches(/^[\s]*[a-zA-Z0-9-_]*[\s]*$/, 'Please, use (a-z, A-Z, 0-9, -, _,)')
		.required('Required field'),
	fees: yup.array().of(
		yup.object().shape({
			fixedValue: yup.string().typeError('Required field').required('Required field'),
			percentValue: yup.string().typeError('Required field').required('Required field'),
		}),
	),
})

const setDefaultRewardFeesScheme = (currencies: Array<Currency>) => {
	let validationSchema = yup.object()

	currencies.forEach(currency => {
		validationSchema = validationSchema.concat(
			yup.object({
				[currency.id]: yup.object({
					fixedValue: yup.string().typeError('Required field').required('Required field'),
					percentValue: yup
						.string()
						.typeError('Required field')
						.required('Required field'),
				}),
			}),
		)
	})

	return validationSchema
}

export const refLinkSchemes = {
	createRefLinkScheme,
	setDefaultRewardFeesScheme,
}

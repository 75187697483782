import RefLinkTableMenuButton from '../../components/referralLinks/referralTable/RefLinkTableMenuButton'
import CopyButton from '../../components/ui/CopyButton'
import { FullReferralLinkDetails } from '../../types/referralLinksTypes'
import { TableConfig } from '../../types/tableTypes'

export const referralLinksTableConfig = (): TableConfig<FullReferralLinkDetails> => {
	return {
		columns: [
			{
				id: 'linkName',
				header: 'Link Name',
				contentColumn: item => item.name,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
				className: 'link-name'
			},
			{
				id: 'copyUrl',
				header: 'Copy URL',
				contentColumn: item => <CopyButton url={item.url} />,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
			},
			// {
			// 	id: 'customerSpread',
			// 	header: 'Customer Spread',
			// 	contentColumn: item => `${item.percentFee.toFixed(2)}%`,
			// 	// width: '20%',
			// 	width: {
			// 		minWidth: 'inherit',
			// 		width: 'inherit',
			// 	},
			// },
			// {
			// 	id: 'customerFee',
			// 	header: 'Customer Fee',
			// 	contentColumn: item => `$${item.fixedFee.toFixed(2)}`,
			// 	// width: '20%',
			// 	width: {
			// 		minWidth: 'inherit',
			// 		width: 'inherit',
			// 	},
			// },
			{
				id: 'inviteCount',
				header: 'Users Registered',
				contentColumn: item => item.inviteCount,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
			},
			{
				id: 'menu',
				header: '',
				contentColumn: item => <RefLinkTableMenuButton refLinkDetails={item} />,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
			},
		],
	}
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { IconButton, Stack, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LazyCustomersTable from '../../components/customers/customersTable/LazyCustomersTable'
import { Customer, CustomerTableFilterFormType } from '../../types/customersTypes'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { customersActionCreators } from '../../store/actionCreators/customersActionCreators'
import { customersTableConfig } from '../../helpers/tableConfigs/customersTableConfig'
import { CustomButton } from '../../components/ui/CustomButton'
// import { openGlobalModal } from '../../store/reducers/globalModalSlice'
import CustomerTableFilter from '../../components/customers/customerTableFilter/CustomerTableFilter'
import { isKwlMAPermission } from '../../helpers/utils/auth'
import TagsForFilter, { FilterTag } from '../../components/ui/TagsForFilter'
import isArray from 'lodash/isArray'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { AutocompleteFieldValue } from '../../types/commonTypes'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { clearFilterStateForMyCustomerTable } from '../../store/reducers/customersSlice'

const StyledMyCustomerPage = styled(Box)(({ theme }) => ({
	'.section-title': {
		paddingLeft: '16px',
		marginBottom: '17px',
		marginTop: '17px',
		[theme.breakpoints.down('md')]: {
			fontSize: '27px',
			lineHeight: '40px',
			paddingLeft: '10px',
			marginBottom: '10px',
		},
	},
	'.MuiGrid-item.filter-wrap': {
		paddingLeft: 0,
		paddingRight: '16px',
		[theme.breakpoints.down('sm')]: {
			'.filter-btn-wrapper': {
				'& > button': {
					marginLeft: '10px',
				},
				'.clear-filter-btn': {
					minWidth: '120px',
				},
				'.total-customers': {
					fontSize: '14px',
					textAlign: 'start',
				},
			},
		},
	},
	// '.MuiGrid-root': {
	// 	[theme.breakpoints.down('sm')]: {
	// 		marginLeft: '10px',
	// 		width: '100%',
	// 		'&>.MuiGrid-item': {
	// 			border: '1px solid red',
	// 			paddingLeft: 0,
	// 			'.row-btn': {
	// 				justifyContent: 'flex-start',
	// 				flexWrap: 'wrap',
	// 				gap: '10px',
	// 				button: {
	// 					margin: '0',
	// 				},
	// 			},
	// 		},
	// 	},
	// },
	'.action-block': {
		marginLeft: 0,
		marginBottom: '24px',
		width: '100%',
	},
}))

const MyCustomersPage = () => {
	const dispatch = useAppDispatch()

	const {
		items: tableData,
		hasNextPage,
		count: customerCount,
	} = useAppSelector(state => state.customers.customerList)
	const { getCustomerListLoadState, filterStateForMyCustomerTable } = useAppSelector(
		state => state.customers,
	)
	const { userPermission } = useAppSelector(state => state.auth)

	const [isShowFilter, setIsShowFilter] = useState(false)
	const [filterList, setFilterList] = useState<CustomerTableFilterFormType>({
		inviteLinkIds: [],
		inviterIds: [],
		query: '',
	})

	const pageSize = 15
	const offset = 0

	const tableConfig = useMemo(() => {
		return customersTableConfig()
	}, [])

	const getCustomerListWithFilters = useCallback(
		(dataForm: CustomerTableFilterFormType) => {
			let queryParams = {}

			for (const prop in dataForm) {
				if (isArray(dataForm[prop])) {
					const valueArr = dataForm[prop].map((item: AutocompleteFieldValue) => item.id)
					queryParams = { ...queryParams, [prop]: valueArr }
				}
				if (typeof dataForm[prop] === 'string' && dataForm[prop]) {
					queryParams = { ...queryParams, [prop]: dataForm[prop] }
				}
			}

			dispatch(customersActionCreators.getCustomerList({ offset, pageSize, queryParams }))

			setFilterList(prevState => ({ ...prevState, ...dataForm }))
		},
		[dispatch],
	)

	const debounceFunction = useMemo(
		() => debounce(getCustomerListWithFilters, 600),
		[getCustomerListWithFilters],
	)

	const onFilterFormSubmit = useCallback(
		(dataForm: CustomerTableFilterFormType) => {
			debounceFunction(dataForm)
		},
		[debounceFunction],
	)

	useEffect(() => {
		if (filterStateForMyCustomerTable.inviteLinkIds.length > 0) {
			onFilterFormSubmit(filterStateForMyCustomerTable)
		} else {
			dispatch(customersActionCreators.getCustomerList({ offset, pageSize }))
		}
	}, [dispatch, filterStateForMyCustomerTable, onFilterFormSubmit])

	useEffect(() => {
		dispatch(customersActionCreators.getInviteLinkListForFilter())
		if (isKwlMAPermission(userPermission)) {
			dispatch(customersActionCreators.getInviterListForFilter())
		}
	}, [dispatch, userPermission])

	useEffect(() => {
		return () => {
			dispatch(clearFilterStateForMyCustomerTable())
		}
	}, [dispatch])

	const onLoadMore = async () => {
		const currentOffset = tableData.length
		let queryParams = {}

		for (const prop in filterList) {
			if (isArray(filterList[prop])) {
				const valueArr = filterList[prop].map((item: AutocompleteFieldValue) => item.id)
				queryParams = { ...queryParams, [prop]: valueArr }
			}
			if (typeof filterList[prop] === 'string' && filterList[prop]) {
				queryParams = { ...queryParams, [prop]: filterList[prop] }
			}
		}

		await dispatch(
			customersActionCreators.getCustomerList({
				offset: currentOffset,
				pageSize,
				queryParams,
			}),
		)
	}

	const [sentryRef, { rootRef }] = useInfiniteScroll({
		loading: getCustomerListLoadState.isLoading,
		hasNextPage,
		onLoadMore,
		// disabled: !!error,
		rootMargin: '0px 0px 400px 0px',
	})

	const onOpenFilter = useCallback(async () => {
		setIsShowFilter(true)
	}, [])

	const onDeleteTag = useCallback(
		(deletedFilterItem: FilterTag) => {
			let modifiedFilterList = { ...filterList }

			if (isArray(modifiedFilterList[deletedFilterItem.nameState])) {
				const updatedList = modifiedFilterList[deletedFilterItem.nameState].filter(
					(item: AutocompleteFieldValue) =>
						typeof deletedFilterItem.valueField !== 'string' &&
						item.id !== deletedFilterItem.valueField.id,
				)
				modifiedFilterList = {
					...modifiedFilterList,
					[deletedFilterItem.nameState]: updatedList,
				}
			}

			if (typeof modifiedFilterList[deletedFilterItem.nameState] === 'string') {
				modifiedFilterList = {
					...modifiedFilterList,
					[deletedFilterItem.nameState]: '',
				}
			}

			let queryParams = {}

			for (const prop in modifiedFilterList) {
				if (isArray(modifiedFilterList[prop])) {
					const valueArr = modifiedFilterList[prop].map(
						(item: AutocompleteFieldValue) => item.id,
					)
					queryParams = { ...queryParams, [prop]: valueArr }
				}

				if (typeof modifiedFilterList[prop] === 'string' && modifiedFilterList[prop]) {
					queryParams = { ...queryParams, [prop]: modifiedFilterList[prop] }
				}
			}

			dispatch(customersActionCreators.getCustomerList({ offset, pageSize, queryParams }))

			setFilterList(prevState => ({ ...prevState, ...modifiedFilterList }))
		},
		[dispatch, filterList],
	)

	const clearFilters = useCallback(() => {
		const emptyFilterList = {
			inviteLinkIds: [],
			inviterIds: [],
			query: '',
		}

		dispatch(customersActionCreators.getCustomerList({ offset, pageSize }))

		setFilterList(emptyFilterList)
	}, [dispatch])

	const modifiedFilterList = useMemo(() => {
		let newList: Array<{
			nameState: string
			valueField: AutocompleteFieldValue | string
		}> = []

		for (const prop in filterList) {
			if (!filterList[prop] || isEmpty(filterList[prop])) {
				continue
			}

			if (isArray(filterList[prop])) {
				filterList[prop].forEach((filterItem: AutocompleteFieldValue) =>
					newList.push({
						nameState: prop,
						valueField: filterItem,
					}),
				)
			}
			if (typeof filterList[prop] === 'string') {
				newList.push({ nameState: prop, valueField: filterList[prop] })
			}
		}

		return newList
	}, [filterList])

	return (
		<StyledMyCustomerPage>
			<Typography variant="h2" className="section-title">
				My Customers
			</Typography>
			<Grid className="action-block" container spacing={2}>
				<Grid item xs={12} sm={4} lg={6}></Grid>
				<Grid className="filter-wrap" item xs={12} sm={8} lg={6}>
					<Stack
						className="filter-btn-wrapper"
						spacing={2}
						direction="row"
						justifyContent="end"
						alignItems="center"
					>
						<div className="total-customers">Total Customers: {customerCount}</div>
						{modifiedFilterList.length > 0 && (
							<CustomButton
								className="clear-filter-btn"
								type="button"
								onClick={clearFilters}
							>
								Clear Filters
							</CustomButton>
						)}
						<IconButton size="large" onClick={onOpenFilter} color="primary">
							<FilterAltIcon />
						</IconButton>
					</Stack>
				</Grid>
			</Grid>
			<CustomerTableFilter
				isShowFilter={isShowFilter}
				setIsShowFilter={setIsShowFilter}
				onFilterFormSubmit={onFilterFormSubmit}
				formState={filterList}
			/>
			<TagsForFilter data={modifiedFilterList} onDeleteTag={onDeleteTag} />
			<LazyCustomersTable<Customer>
				tableData={tableData}
				sentryRef={sentryRef}
				rootRef={rootRef}
				tableConfig={tableConfig}
				loading={getCustomerListLoadState.isLoading}
				// minTableWidth="360px"
				hasNextPage={hasNextPage}
			/>
		</StyledMyCustomerPage>
	)
}

export default MyCustomersPage

import { styled } from '@mui/material'
import { ReactElement } from 'react'
import { Outlet } from 'react-router-dom'

const StyledMain = styled('main')(({ theme }) => ({
	flex: 1,
	padding: '90px 30px 20px',
	//width: 'calc(100% - 66px)',
	width: 'calc(100% - 292px)',
	[theme.breakpoints.down('sm')]: {
		padding: '65px 20px 20px',
	},
	
}))

type MainProps = {
	children: ReactElement
}

const Main = ({ children }: MainProps) => {
	return <StyledMain>{children}</StyledMain>
}

export default Main

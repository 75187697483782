import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { customerServices } from '../../services/customersServices'
import {
	GetCustomersServerRequest,
	SetCustomerFeeServerRequest,
} from '../../types/customersTypes'
import { AlertColor } from '@mui/material'
import { addNotification } from '../reducers/notificationSlice'
import { CustomErrorResponse } from '../../types/axiosTypes'

const getCustomerList = createAsyncThunk(
	'customers/getCustomerList',
	async (arg: GetCustomersServerRequest, { rejectWithValue }) => {
		const { offset, pageSize, queryParams } = arg
		try {
			const response = await customerServices.getCustomerList(offset, pageSize, queryParams)
			// console.log(response)
			return response
		} catch (e) {

			return rejectWithValue('Error')
		}
	},
)

const getInviteLinkListForFilter = createAsyncThunk(
	'customer/getInviteListForFilter',
	async (_, { rejectWithValue }) => {
		try {
			const response = await customerServices.getInviteLinkListForFilter()

			return response
		} catch (e) {
			
			return rejectWithValue('Error')
		}
	},
)

const getInviterListForFilter = createAsyncThunk(
	'customer/getInviterListForFilter',
	async (_, { rejectWithValue }) => {
		try {
			const response = await customerServices.getInviterListForFilter()

			return response
		} catch (e) {

			return rejectWithValue('Error')
		}
	},
)

const getCustomerDetails = createAsyncThunk(
	'customer/getCustomerDetails',
	async (userId: string, { rejectWithValue }) => {
		try {
			const response = await customerServices.getCustomerDetails(userId)

			return response
		} catch (e) {

			return rejectWithValue('Error')
		}
	},
)

const setCustomerFee = createAsyncThunk(
	'customer/setCustomerFee',
	async (arg: SetCustomerFeeServerRequest, { rejectWithValue, dispatch }) => {
		const { userId, body } = arg

		try {
			const response = await customerServices.setCustomerFee(userId, body)

			const notificationData = {
				open: true,
				type: 'success' as AlertColor,
				message: 'Successfully customer data updated',
			}

			dispatch(addNotification(notificationData))

			return response
		} catch (e) {
			const err = e as CustomErrorResponse
			const notificationData = {
				open: true,
				type: 'error' as AlertColor,
				message: err.message,
				errorCode: err.errorCode,
			}

			dispatch(addNotification(notificationData))

			return rejectWithValue('Error')
		}
	},
)

const getCustomerInviteLinkFee = createAsyncThunk(
	'customer/getCustomerInviteLinkFee',
	async (userId: string, { rejectWithValue }) => {
		try {
			const response = await customerServices.getCustomerInviteLinkFee(userId)

			return response
		} catch (e) {

			return rejectWithValue('Error')
		}
	},
)

const getInstanceDefaultRewardFee = createAsyncThunk(
	'customer/getInstanceDefaultRewardFee',
	async (_, { rejectWithValue }) => {
		try {
			const response = await customerServices.getInstanceDefaultRewardFee()

			return response
		} catch (e) {

			return rejectWithValue('Error')
		}
	},
)

export const customersActionCreators = {
	getCustomerList,
	getInviteLinkListForFilter,
	getInviterListForFilter,
	getCustomerDetails,
	setCustomerFee,
	getCustomerInviteLinkFee,
	getInstanceDefaultRewardFee,
}

import { Autocomplete, TextField, styled } from '@mui/material'
import { AutocompleteFieldValue } from '../../types/commonTypes'
import { useCallback, useState } from 'react'

const StyledAutocomplete = styled('div')({
	marginBottom: '20px',

	'.MuiInputBase-input': {
		height: '30px',
	},

	'.MuiChip-label': {
		// maxWidth: '230px',
	},
})

type MultipleSelectorProps = {
	label: string
	options: Array<AutocompleteFieldValue>
	onMainChange: (id: string, value: any) => void
	id: string
}

const MultipleSelector = ({
	label,
	onMainChange,
	options,
	id,
}: MultipleSelectorProps) => {
	const [value, setValue] = useState<Array<string | AutocompleteFieldValue>>([])


	const onChangeField = useCallback(
		(item: Array<string | AutocompleteFieldValue>) => {
			setValue(item)

			onMainChange(id, item)
		},
		[id, onMainChange],
	)

	return (
		<StyledAutocomplete>
			<Autocomplete
				fullWidth
				multiple
				className="autocomplete"
				options={options}
				id={id}
				value={value}
				getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
				renderOption={(props, option) => {
					return (
						<li {...props} key={option.id}>
							{option.name}
						</li>
					)
				}}
				isOptionEqualToValue={(
					option: AutocompleteFieldValue,
					value: AutocompleteFieldValue,
				) => option.id === value.id}
				filterSelectedOptions
				freeSolo
				onChange={(event, item) => onChangeField(item)}
				renderInput={params => <TextField {...params} variant="standard" label={label} />}
			/>
		</StyledAutocomplete>
	)
}

export default MultipleSelector

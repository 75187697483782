import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import LoginForm from '../../components/login/LoginForm'

const StyledLogin = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	// padding: '90px 30px 20px',
	width: '100%',
	// height: '100%',
	/* width */
	'::-webkit-scrollbar': {
		width: '10px'
	},
	/* Track */
	'::-webkit-scrollbar-track': {
		background: '#f1f1f1'
	},
	/* Handle */
	'::-webkit-scrollbar-thumb': {
		background: '#888',
	},
	/* Handle on hover */
	'::-webkit-scrollbar-thumb:hover': {
		background: '#555' 
	}
})

const LoginPage = () => {
	return (
		<StyledLogin>
			<LoginForm />
		</StyledLogin>
	)
}

export default LoginPage

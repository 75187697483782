import { createSlice } from '@reduxjs/toolkit'
import { GlobalModalVariant } from '../../types/commonTypes'

type GlobalModalState = {
	isModalOpen: boolean
	id?: string | number
	variant: GlobalModalVariant
}

const initialState: GlobalModalState = {
	isModalOpen: false,
	// id: '',
	variant: '',
}

export const globalModalSlice = createSlice({
	name: 'globalModal',
	initialState,
	reducers: {
		openGlobalModal: (state, action) => {
			state.isModalOpen = true
			state.variant = action.payload.variant
			state.id = action.payload.id
		},
		closeGlobalModal: state => {
			state.isModalOpen = false
			state.variant = ''
			state.id = ''
		},
	},
})

export const { openGlobalModal, closeGlobalModal } = globalModalSlice.actions

export default globalModalSlice.reducer

import { Box, Chip, styled } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { AutocompleteFieldValue } from '../../types/commonTypes'

export type FilterTag = {
	nameState: string
	// nameField: string
	valueField: AutocompleteFieldValue | string
}

const StyledCustomChip = styled(Chip)({
	marginRight: '10px',
	marginBottom: '10px',
	'.MuiChip-label': {
		// display: 'flex',
		// 'p:first-of-type': {
		// 	fontWeight: 'bold',
		// },
	},
})

type CustomChipProps = {
	item: FilterTag
	onDeleteTag: (deletedFilterItem: FilterTag) => void
}

const CustomChip = ({ item, onDeleteTag }: CustomChipProps) => {
	const onDelete = useCallback(() => {
		onDeleteTag(item)
	}, [item, onDeleteTag])

	const tagValue = useMemo(() => {
		if (typeof item.valueField === 'string') {
			return item.valueField
		}

		return item.valueField.name
	}, [item.valueField])

	return (
		<StyledCustomChip
			// label={
			// 	<>
			// 		<p>{item.nameField}</p>&nbsp;
			// 		<p>{tagValue}</p>
			// 	</>
			// }
			label={tagValue}
			onDelete={onDelete}
		/>
	)
}

const StyledTagsForFilter = styled(Box)({
	// marginBottom: '20px',
})

type TagsForFilterProps = {
	data: Array<FilterTag>
	onDeleteTag: (deletedFilterItem: FilterTag) => void
}

const TagsForFilter = ({ data, onDeleteTag }: TagsForFilterProps) => {
	const getKey = useCallback((item: FilterTag) => {
		if (typeof item.valueField === 'string') {
			return item.valueField
		}

		return item.valueField.id
	}, [])
	return (
		<StyledTagsForFilter>
			{data.map(item => (
				<CustomChip item={item} key={getKey(item)} onDeleteTag={onDeleteTag} />
			))}
		</StyledTagsForFilter>
	)
}

export default TagsForFilter

import { createSlice } from '@reduxjs/toolkit'
import { CustomErrorResponse } from '../../types/axiosTypes'
import { GetReferralLinksResponse, RefLinkDetails } from '../../types/referralLinksTypes'
import { referralLinkActionCreators } from '../actionCreators/referralLinkActionCreators'
import { LoadState, FeeDetailsResponse } from '../../types/commonTypes'

type ReferralLinkState = {
	isLoading: boolean
	isLoaded: boolean
	error: undefined | CustomErrorResponse
	referralLinkList: GetReferralLinksResponse
	instanceHostUrl: string
	refLinkDetails: RefLinkDetails | null
	searchValue: string

	getReferralLinkListLoadState: LoadState

	defaultRewardFeeList: Array<FeeDetailsResponse>
}

const initialState: ReferralLinkState = {
	isLoading: false,
	isLoaded: false,
	error: undefined,
	instanceHostUrl: '',
	referralLinkList: {
		hasNextPage: false,
		items: [],
	},
	refLinkDetails: null,
	searchValue: '',

	getReferralLinkListLoadState: {
		isLoading: false,
		isLoaded: false,
	},

	defaultRewardFeeList: [],
}

export const referralLinkSlice = createSlice({
	name: 'referralLink',
	initialState,
	reducers: {
		removeReferralLinkList: state => {
			state.referralLinkList.hasNextPage = false
			state.referralLinkList.items = []
		},
		removeRefLinkDetails: state => {
			state.refLinkDetails = null
			state.instanceHostUrl = ''
		},
	},
	extraReducers: builder => {
		builder
			.addCase(referralLinkActionCreators.getReferralLinkList.pending, state => {
				state.getReferralLinkListLoadState.isLoading = true
				state.error = undefined

				// state.referralLinkList.items =
				// action.meta.arg.offset === 0 ? [] : state.referralLinkList.items
				// state.error = undefined
			})
			.addCase(
				referralLinkActionCreators.getReferralLinkList.fulfilled,
				(state, action) => {
					state.getReferralLinkListLoadState.isLoading = false
					state.referralLinkList.hasNextPage = action.payload.hasNextPage
					// state.referralLinkList.items = [
					// 	...state.referralLinkList.items,
					// 	...action.payload.items,
					// ]
					state.referralLinkList.items = action.payload.items
					state.searchValue = action.meta.arg
				},
			)
			.addCase(referralLinkActionCreators.getReferralLinkList.rejected, state => {
				state.getReferralLinkListLoadState.isLoading = false
			})
			.addCase(referralLinkActionCreators.getKWLInstanceInfo.pending, state => {
				state.isLoading = true
				state.error = undefined
			})
			.addCase(
				referralLinkActionCreators.getKWLInstanceInfo.fulfilled,
				(state, action) => {
					state.instanceHostUrl = action.payload.hostUrl
				},
			)
			.addCase(
				referralLinkActionCreators.getKWLInstanceInfo.rejected,
				(state, action) => {},
			)
			.addCase(referralLinkActionCreators.createRefLink.fulfilled, (state, action) => {
				state.referralLinkList.items = action.payload.items
			})

			.addCase(referralLinkActionCreators.updateRefLink.fulfilled, (state, action) => {
				state.referralLinkList.items = action.payload.items
			})

			.addCase(referralLinkActionCreators.deleteRefLink.fulfilled, (state, action) => {
				state.referralLinkList.items = state.referralLinkList.items.filter(
					refLink => refLink.id !== action.meta.arg,
				)
			})
			.addCase(
				referralLinkActionCreators.getRefLinkDetailsById.fulfilled,
				(state, action) => {
					state.refLinkDetails = action.payload
					state.instanceHostUrl = action.payload.url.slice(
						0,
						action.payload.url.indexOf('/login'),
					)
				},
			)
			.addCase(
				referralLinkActionCreators.getDefaultRewardFeeList.fulfilled,
				(state, action) => {
					state.defaultRewardFeeList = action.payload.fees
				},
			)
	},
})

export const { removeRefLinkDetails, removeReferralLinkList } = referralLinkSlice.actions

export default referralLinkSlice.reducer

import { styled } from '@mui/material/styles'
import { memo } from 'react'
import { TableConfig } from '../../../types/tableTypes'

type StyledRowCellProps = {
	width: {
		minWidth: string
		width: string
	}
}

export const StyledRowCell = styled('div', {
	shouldForwardProp: prop => prop !== 'width',
})<StyledRowCellProps>(({ width, theme }) => ({
	width: width.width,
	// wordBreak: 'break-all',
	minWidth: width.minWidth,
	padding: '0 15px',
	textAlign: 'center',

	[theme.breakpoints.down('sm')]: {
		padding: '0 5px',
	},
}))

type StyledTableRowProps = {
	rowIndex: number
	isExistOnClick: boolean
}

export const StyledTableRow = styled('div')<StyledTableRowProps>(
	({ theme, rowIndex, isExistOnClick }) => ({
		display: 'grid',
		gridTemplateColumns:
			'minmax(140px, 1fr) minmax(200px, 1fr) minmax(100px, 1fr) minmax(180px, 1fr) repeat(3, minmax(160px, 1fr))  minmax(80px, 1fr)',
		height: '43px',
		lineHeight: '43px',
		width: '100%',
		// padding: '0 23px',
		// minWidth: 'fit-content',
		// width: '100%',

		// backgroundColor: rowIndex % 2 ? 'white' : 'whitesmoke',
		// borderBottom: rowIndex % 2 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
		// borderTop: rowIndex % 2 ? 'none' : '1px solid rgba(224, 224, 224, 1)',

		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		// '&:hover': isExistOnClick && {
		// 	backgroundColor: '#B8B8B8',
		// 	cursor: 'pointer',
		// },

		'#email, #phoneNumber, #walletAddressUsd': {
			wordBreak: 'break-all',
		},
		'#country': {
			svg: {
				verticalAlign: 'middle',
			},
		},
		'#linkName, #customer-name': {
			display: 'block',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},

		backgroundColor:
			rowIndex % 2 ? theme.palette.secondary.contrastText : theme.palette.secondary.dark,
		'&:nth-of-type(odd) > div': {
			backgroundColor: theme.palette.secondary.dark,
			'.btn-more': {
				background: 'white',
			},
		},
		'&:nth-of-type(even) > div': {
			backgroundColor: theme.palette.secondary.contrastText,
			'.btn-more': {
				background: '#EFF6FF',
			},
		},
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
			cursor: 'pointer',
		},
		'&:hover > div': {
			backgroundColor: theme.palette.action.hover,
			cursor: 'pointer',
		},
		'.table-item': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '43px',
			fontSize: '1rem',

			'&:not(.status)': {
				color: theme.palette.primary.main,
			},
			'&.name': {
				textDecoration: 'underline',
				fontWeight: 500,
				button: {
					display: 'block',
					width: '95%',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					textTransform: 'initial',
					textAlign: 'left',
					'&:hover': {
						background: 'transparent',
					},
				},
			},
			'&.btn-more': {
				justifyContent: 'flex-end',
				paddingRight: '10px',
				maxWidth: '60px',
				'& > div': {
					width: '100%',
				},
			},
			a: {
				color: theme.palette.primary.main,
			},
		},
		[theme.breakpoints.down('sm')]: {
			height: '40px',
			lineHeight: '40px',
			gridTemplateColumns:
				'minmax(110px, 1fr) minmax(170px, 1fr) minmax(70px, 1fr) minmax(150px, 1fr) repeat(3, minmax(125px, 1fr))  minmax(60px, 1fr)',
			'.table-item': {
				height: '40px',
				fontSize: '14px',
				lineHeight: '40px',
				'& > button': {
					padding: '4px 8px 4px 0;',
					fontSize: '14px',
				},
			},
		},
	}),
)

type TableRowProps<T> = {
	rowData: T
	rowIndex: number
	tableConfig: TableConfig<T>
	// sentryRef?: LegacyRef<HTMLDivElement>
	onClick?: (id: string | number) => void
	isExistOnClick: boolean
}

const TableRow = <T extends { extId?: string; id?: string | number }>({
	rowData,
	tableConfig,
	rowIndex,
	// sentryRef,
	onClick,
	isExistOnClick,
}: TableRowProps<T>) => {
	return (
		<StyledTableRow
			className="table-row"
			rowIndex={rowIndex}
			isExistOnClick={isExistOnClick}
			// ref={sentryRef}
			{...(onClick && { onClick: () => rowData.extId && onClick(rowData.extId) })}
		>
			{tableConfig.columns.map(column => (
				<StyledRowCell
					// ref={sentryRef}
					id={column.id}
					key={column.id}
					className="table-item"
					width={column.width}
				>
					{column.contentColumn(rowData)}
				</StyledRowCell>
			))}
		</StyledTableRow>
	)
}

export default memo(TableRow) as typeof TableRow

import {
	StyledCloseButtonModal,
	StyledDialog,
	StyledDialogActions,
	StyledDialogBody,
	StyledDialogContent,
	StyledDialogContentText,
	StyledDialogHeader,
	StyledDialogTitle,
} from '../../modal/styledModalComponent'
import CancelIcon from '@mui/icons-material/Cancel'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { CustomButton, SecondaryButton } from '../../ui/CustomButton'
import { useEffect, useMemo, useState } from 'react'
import { referralLinkActionCreators } from '../../../store/actionCreators/referralLinkActionCreators'
import { removeRefLinkDetails } from '../../../store/reducers/referralLinkSlice'
import SetDefaultRewardFeesForm from './SetDefaultRewardFeesForm'
import { closeGlobalModal } from '../../../store/reducers/globalModalSlice'
import { styled } from '@mui/material'
import { FeeDetailsRequest } from '../../../types/commonTypes'

const StyledSetDefaultRewardFeesModal = styled(StyledDialog)({
	'.MuiDialog-paper': {
		width: '550px',
		'.MuiDialogTitle-root': {
			width: 'unset',
		},
	},
})

type SetDefaultRewardFessModalProps = {
	isOpenDialog: boolean
}

const SetDefaultRewardFessModal = ({ isOpenDialog }: SetDefaultRewardFessModalProps) => {
	const dispatch = useAppDispatch()

	const { defaultRewardFeeList } = useAppSelector(state => state.referralLink)

	useEffect(() => {
		dispatch(referralLinkActionCreators.getDefaultRewardFeeList())
	}, [dispatch])

	const [isDisabledBtn, setIsDisabledBtn] = useState(false)

	const currencies = useMemo(
		() => defaultRewardFeeList.map(rewardFee => rewardFee.currency),
		[defaultRewardFeeList],
	)

	useEffect(() => {
		return () => {
			dispatch(removeRefLinkDetails())
		}
	}, [dispatch])

	const closeModal = () => {
		dispatch(closeGlobalModal())
	}

	const onSave = async (dataForm: Array<FeeDetailsRequest>) => {
		const body = {
			fees: dataForm,
		}

		dispatch(referralLinkActionCreators.updateDefaultRewardFeeList(body))
		closeModal()
	}

	return defaultRewardFeeList.length > 0 && currencies.length > 0 ? (
		<StyledSetDefaultRewardFeesModal open={isOpenDialog} onClose={closeModal}>
			<StyledDialogHeader>
				<StyledDialogTitle>Set Default Fees</StyledDialogTitle>
				<StyledCloseButtonModal disableRipple onClick={closeModal}>
					Close
					<CancelIcon />
				</StyledCloseButtonModal>
			</StyledDialogHeader>
			<StyledDialogBody>
				<StyledDialogContentText>
					View and edit default fees and rates
				</StyledDialogContentText>
				<StyledDialogContent>
					<Paper elevation={0} className="user-info-action">
						<Grid container>
							<Grid item xs={12}>
								<SetDefaultRewardFeesForm
									defaultRewardFeeList={defaultRewardFeeList}
									setIsDisabledBtn={setIsDisabledBtn}
									currencies={currencies}
									onSave={onSave}
								/>
							</Grid>
						</Grid>
					</Paper>
				</StyledDialogContent>
				<StyledDialogActions sx={{ justifyContent: 'space-between' }}>
					<SecondaryButton type="button" onClick={closeModal}>
						Cancel
					</SecondaryButton>
					<CustomButton
						type="submit"
						disabled={isDisabledBtn}
						form="set-default-reward-fees-form"
					>
						Save
					</CustomButton>
				</StyledDialogActions>
			</StyledDialogBody>
		</StyledSetDefaultRewardFeesModal>
	) : null
}

export default SetDefaultRewardFessModal

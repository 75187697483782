import { authorizedRequest } from '../api/authorizeRequest'
import { FeeDetailsResponse } from '../types/commonTypes'
import {
	FeeDetails,
	CustomerDetails,
	GetCustomersServerResponse,
	InviteLinkForFilter,
	InviterListForFilter,
	SetCustomerFeeServerRequestBody,
	GetInstanceDefaultRewardFeeResponse,
} from '../types/customersTypes'

const getCustomerList = (
	offset: number,
	step: number,
	queryParams?: Record<string, string | Array<number | string>>,
) => {
	const path = ['users']

	const query = {
		offset,
		pageSize: step,
		...queryParams,
	}

	return authorizedRequest<null, GetCustomersServerResponse>(path, 'GET', null, query)
}

const getInviteLinkListForFilter = () => {
	const path = ['invite-links', 'filter-list']

	return authorizedRequest<null, Array<InviteLinkForFilter>>(path)
}

const getInviterListForFilter = () => {
	const path = ['agents']

	return authorizedRequest<null, Array<InviterListForFilter>>(path)
}

const getCustomerDetails = (userId: string) => {
	const path = ['users', userId]

	return authorizedRequest<null, CustomerDetails>(path)
}

const setCustomerFee = (userId: string, body: SetCustomerFeeServerRequestBody) => {
	const path = ['users', userId]

	return authorizedRequest<SetCustomerFeeServerRequestBody, null>(path, 'PUT', body)
}

const getCustomerInviteLinkFee = (userId: string) => {
	const path = ['users', userId, 'invite-link-fees']

	return authorizedRequest<null, Array<FeeDetailsResponse>>(path)
}

const getInstanceDefaultRewardFee = () => {
	const path = ['instance', 'fees', 'agent-reward']

	return authorizedRequest<null, GetInstanceDefaultRewardFeeResponse>(path)
}

export const customerServices = {
	getCustomerList,
	getInviteLinkListForFilter,
	getInviterListForFilter,
	getCustomerDetails,
	setCustomerFee,
	getCustomerInviteLinkFee,
	getInstanceDefaultRewardFee,
}

import React, { useRef } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { localStorageData } from '../../helpers/localStorage'
import Header from './Header'

const ProtectedLoginRoute = () => {
	const token = useRef(localStorageData.getData('authData'))

	console.log(token.current)
	return token.current ? (
		<Navigate to="/referral-links" />
	) : (
		<>
			<Header withSideBar={false} />
			<Outlet />
		</>
	)
	// return <Outlet />
}

export default ProtectedLoginRoute

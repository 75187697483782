import { styled } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoginIcon from '@mui/icons-material/Login'
import { Typography, useTheme } from '@mui/material'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { toggleTheme } from '../../store/reducers/themeSlice'
import { CustomIcon, StyledIconWrapper } from '../../helpers/customIcon'
import { useCallback } from 'react'
import { authActionCreators } from '../../store/actionCreators/authActionCreators'
import { useNavigate } from 'react-router-dom'
import { localStorageData } from '../../helpers/localStorage'

interface AppBarProps extends MuiAppBarProps {
	open?: boolean
	withSideBar: boolean
}
const AppBar = styled(MuiAppBar, {
	shouldForwardProp: prop => prop !== 'open' && prop !== 'withSideBar',
})<AppBarProps>(({ theme, open, withSideBar }) => ({
	backgroundImage: 'none',
	backgroundColor: theme.palette.background.paper,
	...(withSideBar
		? {
				...(open && {
					marginLeft: '292px',
					width: `calc(100% - 292px)`,
					transition: theme.transitions.create(['width', 'margin'], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.enteringScreen,
					}),
					[theme.breakpoints.down('sm')]: {
						width: '100%',
					},
				}),
				...(!open && {
					width: `calc(100% - 66px)`,
					[theme.breakpoints.down('sm')]: {
						width: '100%',
					},
				}),
		  }
		: {
				width: '100%',
		  }),
	'.header-row': {
		padding: '16px 23px',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			padding: '8px 20px 8px 8px',
		},
	},
	'.header-title': {
		paddingLeft: '7px',
		color: theme.palette.text.primary,
		flex: '1',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.7rem',
			lineHeight: '1.2',
		},
	},
}))

type HeaderProps = {
	isOpenMenu?: boolean
	onToggleMenu?: () => void
	withSideBar: boolean
	// setOpen: Dispatch<SetStateAction<boolean>>
}
const Header = ({ isOpenMenu, onToggleMenu, withSideBar }: HeaderProps) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const theme = useTheme()

	const name = useAppSelector(state => state.auth.userDetails?.name)

	// const token = useRef(localStorageData.getData('authData'))
	const token = localStorageData.getData('authData')

	const onLogOut = useCallback(() => {
		dispatch(authActionCreators.logOut({ navigate }))

		// navigate('/')
	}, [dispatch, navigate])

	return (
		<AppBar
			withSideBar={withSideBar}
			open={isOpenMenu}
			position="fixed"
			sx={{ boxShadow: 'none' }}
		>
			<Toolbar className="header-row">
				{withSideBar && (
					<IconButton
						onClick={onToggleMenu}
						sx={{
							// marginRight: 5,
							display: { xs: 'flex', sm: 'none' },
							// ...(open && { display: 'none' }),
							color: theme.palette.text.primary,
						}}
					>
						<MenuOpenIcon />
					</IconButton>
				)}
				{token && (
					<Typography className="header-title" variant="h1" noWrap component="div">
						{`Master Agent - ${name}`}
					</Typography>
				)}
				{!withSideBar &&
					(theme.palette.mode === 'dark' ? (
						<StyledIconWrapper>
							<CustomIcon.DarkLogo />
						</StyledIconWrapper>
					) : (
						<StyledIconWrapper>
							<CustomIcon.LightLogo />
						</StyledIconWrapper>
					))}
				<IconButton
					sx={{ ml: 1, color: theme.palette.text.primary }}
					onClick={() => dispatch(toggleTheme())}
				>
					{theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
				</IconButton>
				{token && (
					<IconButton
						sx={{ ml: 1, color: theme.palette.text.primary }}
						onClick={onLogOut}
					>
						<LoginIcon />
					</IconButton>
				)}
			</Toolbar>
		</AppBar>
	)
}

export default Header

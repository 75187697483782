import { useAppDispatch } from '../../hooks/redux'
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { styled } from '@mui/material/styles'
import CancelIcon from '@mui/icons-material/Cancel'
import {
	StyledCloseButtonModal,
	StyledDialog,
	StyledDialogActions,
	StyledDialogBody,
	StyledDialogContent,
	StyledDialogContentText,
	StyledDialogHeader,
	StyledDialogTitle,
} from './styledModalComponent'
import {CustomButton} from '../ui/CustomButton'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
	width: '300px',
})

type DialogModalProps = {
	isOpenDialog: boolean
	onCloseModalClick: ActionCreatorWithoutPayload<'globalModal/closeGlobalModal'>
}

const DialogModal = ({ isOpenDialog, onCloseModalClick }: DialogModalProps) => {
	const dispatch = useAppDispatch()

	const closeModal = () => {
		dispatch(onCloseModalClick())
	}
	return (
		<StyledDialog open={isOpenDialog} onClose={closeModal}>
			<StyledDialogHeader>
				<StyledDialogModalTitle>Block Customer</StyledDialogModalTitle>
				<StyledCloseButtonModal onClick={closeModal}>
					Close
					<CancelIcon />
				</StyledCloseButtonModal>
			</StyledDialogHeader>
			<StyledDialogBody>
				<StyledDialogContent>
					<StyledDialogContentText>
						Are you sure you want to block this client?
					</StyledDialogContentText>
				</StyledDialogContent>
				<StyledDialogActions>
					<CustomButton type="button" onClick={closeModal}>
						Cancel
					</CustomButton>
					<CustomButton type="button" onClick={closeModal}>
						Block client
					</CustomButton>
				</StyledDialogActions>
			</StyledDialogBody>
		</StyledDialog>
	)
}

export default DialogModal

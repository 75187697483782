import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { styled, useTheme } from '@mui/material/styles'
import TextFieldForm from '../ui/TextFieldForm'
import SelectFieldForm from '../ui/SelectFieldForm'
import Grid from '@mui/material/Grid'
import {
	CustomerDetailsForForm,
	CustomerDetailsForm,
	OverrideLevelForFee,
	SetCustomerFeeServerRequestBody,
} from '../../types/customersTypes'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import CustomTypography from '../ui/CustomTypography'
import { getOverrideLevelOptions } from '../../helpers/utils/customer'
import { customersSchemes } from '../../helpers/resolverScheme/customersSchemes'
import { isEmpty } from 'lodash'
import { FeeDetailsResponse } from '../../types/commonTypes'

const StyledEditRatesForm = styled('form')({
	'.MuiGrid-item': {
		paddingTop: 0,
	},
	'.currency-title': {
		fontSize: '21px',
		margin: '20px 0 10px',
	},
})

type EditRatesFormProps = {
	userDataForm: CustomerDetailsForForm | null
	customerInviteLinkFee: Array<FeeDetailsResponse>
	instanceDefaultRewardFees: Array<FeeDetailsResponse>
	setIsDisabledBtn: Dispatch<SetStateAction<boolean>>
	onSave: (dataForm: SetCustomerFeeServerRequestBody) => void
}

const EditRatesForm = ({
	userDataForm,
	customerInviteLinkFee,
	instanceDefaultRewardFees,
	setIsDisabledBtn,
	onSave,
}: EditRatesFormProps) => {
	const theme = useTheme()

	const overrideLevelOptions = useMemo(() => {
		const isCustomerFromLink = customerInviteLinkFee.length > 0
		const modifiedOptionList = getOverrideLevelOptions(isCustomerFromLink)

		return modifiedOptionList
	}, [customerInviteLinkFee.length])

	const { handleSubmit, register, formState, control, setValue, watch, clearErrors } =
		useForm<CustomerDetailsForm>({
			resolver: yupResolver(customersSchemes.editingCustomerDetailsScheme),
		})

	const overrideLevelWatch = watch('overrideLevel')

	const setFormValue = useCallback(
		(userData: CustomerDetailsForForm) => {
			setValue('overrideLevel', userData.overrideLevel)
			userData.fees.forEach((feeEntry, index) => {
				setValue(`fees.${index}.currency`, `${feeEntry.currency.id}`)
				setValue(
					`fees.${index}.fixedValue`,
					feeEntry.fixedValue || feeEntry.fixedValue === 0
						? `${feeEntry.currency.sign}${feeEntry.fixedValue}`
						: '',
				)
				setValue(
					`fees.${index}.percentValue`,
					feeEntry.percentValue || feeEntry.percentValue === 0
						? `${feeEntry.percentValue}%`
						: '',
				)
			})
		},
		[setValue],
	)

	useEffect(() => {
		if (userDataForm) {
			setFormValue(userDataForm)
		}
	}, [setFormValue, userDataForm])

	useEffect(() => {
		if (isEmpty(formState.errors)) {
			setIsDisabledBtn(false)
		} else {
			setIsDisabledBtn(true)
		}
	}, [formState, setIsDisabledBtn])

	const onOverrideLevelChange = useCallback(
		(value: number | string) => {
			if (Number(value) === OverrideLevelForFee.FromLink) {
				customerInviteLinkFee.forEach((feeItem, index) => {
					setValue(`fees.${index}.percentValue`, `${feeItem.value.percentValue}%`)
					setValue(
						`fees.${index}.fixedValue`,
						`${feeItem.currency.sign}${feeItem.value.fixedValue}`,
					)
					clearErrors()
				})
			}

			if (Number(value) === OverrideLevelForFee.Default) {
				instanceDefaultRewardFees.forEach((feeEntry, index) => {
					if (feeEntry.currency.id === 'GBP') {
						setValue(`fees.0.currency`, `${feeEntry.currency.id}`)
						setValue(`fees.0.percentValue`, `${feeEntry.value.percentValue}%`)
						setValue(
							`fees.0.fixedValue`,
							`${feeEntry.currency.sign}${feeEntry.value.fixedValue}`,
						)
						clearErrors()
					}
				})
			}

			if (Number(value) === OverrideLevelForFee.Custom) {
				userDataForm &&
					userDataForm.fees.forEach((feeEntry, index) => {
						setValue(`fees.${index}.currency`, `${feeEntry.currency.id}`)
						setValue(`fees.${index}.fixedValue`, '')
						setValue(`fees.${index}.percentValue`, '')
					})
			}
		},
		[
			clearErrors,
			customerInviteLinkFee,
			instanceDefaultRewardFees,
			setValue,
			userDataForm,
		],
	)

	const modifyNumberTextField = useCallback((number: string | number) => {
		const modifiedNumberValue = String(number)
			.replace(/[^0-9.]/g, '')
			.replace(/\.$/, '')

		return Number(modifiedNumberValue)
	}, [])

	const onSubmit = (dataForm: CustomerDetailsForm) => {
		const fees = dataForm.fees.map(feeEntry => ({
			currency: { id: feeEntry.currency },
			value: {
				fixedValue: modifyNumberTextField(feeEntry.fixedValue),
				percentValue: modifyNumberTextField(feeEntry.percentValue),
			},
		}))

		const body = {
			fees,
			overrideLevel: dataForm.overrideLevel,
		}

		onSave(body)
	}

	return (
		<StyledEditRatesForm id="edit-customer-fee" onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2}>
				<Grid item md={6} xs={12}>
					<SelectFieldForm<CustomerDetailsForm>
						name="overrideLevel"
						control={control}
						label="Fee Variant Setting"
						options={overrideLevelOptions}
						onChangeField={onOverrideLevelChange}
					/>
				</Grid>
			</Grid>

			{userDataForm?.fees.map((feeItem, index) => (
				<React.Fragment key={feeItem.currency.id}>
					<CustomTypography
						colorText={theme.palette.primary.main}
						className="currency-title"
					>
						{`${feeItem.currency.id}:`}
					</CustomTypography>
					<input {...register(`fees.${index}.currency`)} hidden />
					<Grid container spacing={2}>
						<Grid item md={6} xs={12} pl={0}>
							<TextFieldForm<CustomerDetailsForm>
								name={`fees.${index}.percentValue` as const}
								control={control}
								label="Percent Fee"
								type="tel"
								autoComplete="off"
								inputMode="numeric"
								suffix="%"
								maxLength={7}
								disabled={
									overrideLevelWatch === OverrideLevelForFee.FromLink ||
									overrideLevelWatch === OverrideLevelForFee.Default
								}
							/>
						</Grid>
						<Grid item md={6} xs={12} pl={0}>
							<TextFieldForm<CustomerDetailsForm>
								name={`fees.${index}.fixedValue` as const}
								control={control}
								label="Fixed Fee"
								type="tel"
								inputMode="numeric"
								autoComplete="off"
								prefix={feeItem.currency.sign}
								maxLength={7}
								disabled={
									overrideLevelWatch === OverrideLevelForFee.FromLink ||
									overrideLevelWatch === OverrideLevelForFee.Default
								}
							/>
						</Grid>
					</Grid>
				</React.Fragment>
			))}
		</StyledEditRatesForm>
	)
}

export default EditRatesForm

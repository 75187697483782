import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit'
import authSlice from './reducers/authSlice'
import notificationSlice from './reducers/notificationSlice'
import globalModalSlice from './reducers/globalModalSlice'
import metricsSlice from './reducers/metricsSlice'
import referralLinkSlice from './reducers/referralLinkSlice'
import themeSlice from './reducers/themeSlice'
import customersSlice from './reducers/customersSlice'
// import userReducer from "./reducers/userReducer";

const rootReducer = combineReducers({
	// user: userReducer,
	globalModal: globalModalSlice,
	notification: notificationSlice,
	auth: authSlice,
	theme: themeSlice,
	metrics: metricsSlice,
	customers: customersSlice,
	referralLink: referralLinkSlice,
})

// const reset = (state: ReturnType<typeof rootReducer>, action: AnyAction) => {
// 	if (action.type === 'user/reset') {
// 		return rootReducer(undefined, action)
// 	}

// 	return rootReducer(state, action)
// }

export const setupStore = () => {
	return configureStore({
		reducer: rootReducer,
		// @ts-ignore
		// reducer: reset,
	})
}
// export const store = configureStore({
// 	reducer: rootReducer,
// })

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

import { useController, UseControllerProps, FieldValues } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { FormHelperText } from '@mui/material'
import { useCallback } from 'react'

const StyledSelectFieldForm = styled(FormControl)({
	width: '100%',
	'.MuiInput-root': {
		'&.Mui-focused': {
			background: 'transparent',
			boxShadow: 'none',
		},
	},
	'.MuiInputBase-root.Mui-focused > div': {
		background: 'transparent',
		boxShadow: 'none',
	},
})

type SelectFieldFormProps<T extends FieldValues> = UseControllerProps<T> & {
	label: string
	options: { label: string; value: string | number }[]
	onChangeField: (value: number | string) => void
}

const SelectFieldForm = <T extends FieldValues>({
	name,
	control,
	label,
	options,
	onChangeField,
}: SelectFieldFormProps<T>) => {
	const {
		field: { onChange, value },
		fieldState: { error: fieldError },
		// formState: { errors },
	} = useController({
		name,
		control,
	})

	const onChangeSelectField = useCallback(
		(event: SelectChangeEvent) => {
			const value = event.target.value

			onChangeField(value)
			onChange(value)
		},
		[onChange, onChangeField],
	)

	return (
		<StyledSelectFieldForm variant="standard">
			<InputLabel id="select-label">{label}</InputLabel>
			<Select
				labelId="select-label"
				id="select-label"
				value={typeof value === 'undefined' ? '' : value}
				onChange={onChangeSelectField}
				// helperText='test'
			>
				{options.map(option => (
					<MenuItem value={option.value} key={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{fieldError?.message ? fieldError?.message : ''}</FormHelperText>
		</StyledSelectFieldForm>
	)
}

export default SelectFieldForm

import { Box, Drawer, IconButton, Typography, styled } from '@mui/material'
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import MultipleSelectForm from '../../ui/MultipleSelectForm'
import CustomerTableFilterForm from './CustomerTableFilterForm'
import { CustomerTableFilterFormType } from '../../../types/customersTypes'
import CancelIcon from '@mui/icons-material/Cancel'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
	'.MuiDrawer-paper': {
		width: '360px',
		padding: '20px 25px',
		backgroundColor: theme.palette.background.default,
		backgroundImage: 'none',
		// '&.MuiPaper-root': {

		// },
		'.MuiBox-root': {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			'.MuiIconButton-root': {
				paddingRight: 0,
				color: theme.palette.primary.contrastText,
				'&:hover': {
					// backgroundColor: 'transparent',
					color: '#3F4153',
					transition: '0.3s',
				},
			},
		},
	},
}))

type CustomerTableFilterProps = {
	isShowFilter: boolean
	setIsShowFilter: Dispatch<SetStateAction<boolean>>
	onFilterFormSubmit: (dataForm: CustomerTableFilterFormType) => void
	formState: CustomerTableFilterFormType
}

const CustomerTableFilter = ({
	isShowFilter,
	setIsShowFilter,
	onFilterFormSubmit,
	formState,
}: CustomerTableFilterProps) => {
	const onCloseFilter = useCallback(() => {
		setIsShowFilter(false)
	}, [setIsShowFilter])
	return (
		<StyledDrawer anchor="right" open={isShowFilter} onClose={onCloseFilter}>
			<Box>
				<Typography fontSize={20}>Filters</Typography>
				<IconButton size="large" onClick={onCloseFilter} disableRipple>
					<CancelIcon />
				</IconButton>
			</Box>
			<CustomerTableFilterForm
				onFilterFormSubmit={onFilterFormSubmit}
				formState={formState}
			/>
		</StyledDrawer>
	)
}

export default CustomerTableFilter

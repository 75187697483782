import * as yup from 'yup'

const editingCustomerDetailsScheme = yup.object({
	overrideLevel: yup.string().typeError('Required field').required('Required field'),
	fees: yup.array().of(
		yup.object().shape({
			fixedValue: yup.string().typeError('Required field').required('Required field'),
			percentValue: yup.string().typeError('Required field').required('Required field'),
		}),
	),
})

export const customersSchemes = {
	editingCustomerDetailsScheme,
}

import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { Paper, Stack, useTheme } from '@mui/material'
import {
	StyledCloseButtonModal,
	StyledDialog,
	StyledDialogActions,
	StyledDialogBody,
	StyledDialogContent,
	StyledDialogContentText,
	StyledDialogHeader,
	StyledDialogTitle,
} from '../../modal/styledModalComponent'
import CancelIcon from '@mui/icons-material/Cancel'
import CustomTypography from '../../ui/CustomTypography'
import EditRatesForm from '../../metricsDashboard/EditRatesForm'
import { CustomButton, SecondaryButton } from '../../ui/CustomButton'
import {
	FeeDetailsForForm,
	OverrideLevelForFee,
	SetCustomerFeeServerRequestBody,
} from '../../../types/customersTypes'
import {
	changeFilterStateForMyCustomerTable,
	removeCustomerDetails,
} from '../../../store/reducers/customersSlice'
import { customersActionCreators } from '../../../store/actionCreators/customersActionCreators'

type EditingCustomerDetailsModalProps = {
	isOpenDialog: boolean
	onCloseModalClick: ActionCreatorWithoutPayload<'globalModal/closeGlobalModal'>
}

const EditingCustomerDetailsModal = ({
	isOpenDialog,
	onCloseModalClick,
}: EditingCustomerDetailsModalProps) => {
	const dispatch = useAppDispatch()
	const theme = useTheme()
	const {
		customerDetails: { userData, customerInviteLinkFee },
		instanceDefaultRewardFees,
	} = useAppSelector(state => state.customers)

	const [isDisabledBtn, setIsDisabledBtn] = useState(false)

	const customerDetailsForm = useMemo(() => {
		let feesArrForm: Array<FeeDetailsForForm> = []
		let overrideLevel: OverrideLevelForFee = userData?.overrideLevel
			? userData?.overrideLevel
			: OverrideLevelForFee.Custom

		if ((userData && userData.fees.length === 0) || !userData) {
			if (overrideLevel === OverrideLevelForFee.Default) {
				instanceDefaultRewardFees.forEach(feeEntry => {
					if (feeEntry.currency.id === 'GBP') {
						feesArrForm = [
							{
								currency: feeEntry.currency,
								fixedValue: feeEntry.value.fixedValue,
								percentValue: feeEntry.value.percentValue,
							},
						]
					}
				})
			} else {
				feesArrForm = [
					{
						currency: { id: 'GBP', displayName: 'pound', sign: '£' },
						fixedValue: '',
						percentValue: '',
					},
				]
			}
		}

		if (userData && userData.fees.length > 0) {
			feesArrForm = userData.fees.map(feeEntry => ({
				currency: feeEntry.currency,
				fixedValue: feeEntry.value.fixedValue,
				percentValue: feeEntry.value.percentValue,
			}))
		}

		if (
			userData?.overrideLevel === OverrideLevelForFee.FromLink &&
			customerInviteLinkFee.length === 0
		) {
			overrideLevel = OverrideLevelForFee.Custom
		}

		return {
			fees: feesArrForm,
			overrideLevel,
		}
	}, [customerInviteLinkFee.length, instanceDefaultRewardFees, userData])

	useEffect(() => {
		return () => {
			dispatch(removeCustomerDetails())
		}
	}, [dispatch])

	const closeModal = useCallback(() => {
		dispatch(onCloseModalClick())
	}, [dispatch, onCloseModalClick])

	const goToCustomerTableByLinkName = useCallback(() => {
		const filterState = {
			inviteLinkIds: [userData?.inviteLink],
			query: '',
		}

		dispatch(changeFilterStateForMyCustomerTable(filterState))
		closeModal()
	}, [closeModal, dispatch, userData?.inviteLink])

	const onSave = async (dataForm: SetCustomerFeeServerRequestBody) => {
		if (userData?.id) {
			const arg = {
				userId: userData.id,
				body: dataForm,
			}

			dispatch(customersActionCreators.setCustomerFee(arg))
				.unwrap()
				.then(() => closeModal())
				.catch(() => closeModal())
		}
	}

	return (
		<StyledDialog open={isOpenDialog} onClose={closeModal}>
			<StyledDialogHeader>
				<StyledDialogTitle>{`${userData?.firstName} ${userData?.lastName}`}</StyledDialogTitle>
				<StyledCloseButtonModal disableRipple onClick={closeModal}>
					Close
					<CancelIcon />
				</StyledCloseButtonModal>
			</StyledDialogHeader>
			<StyledDialogBody>
				<StyledDialogContentText>
					View and edit customer fees and rates
				</StyledDialogContentText>
				<StyledDialogContent>
					<Paper elevation={0} className="user-info-dialog">
						<Stack
							direction="row"
							justifyContent="space-between"
							className="user-info-dialog__row"
							spacing={2}
						>
							<CustomTypography colorText={theme.palette.text.secondary}>
								Date Of Birth:
							</CustomTypography>
							<CustomTypography colorText={theme.palette.text.secondary}>
								{userData?.dateOfBirth || ''}
							</CustomTypography>
						</Stack>
						<Stack
							direction="row"
							justifyContent="space-between"
							className="user-info-dialog__row"
							spacing={2}
						>
							<CustomTypography colorText={theme.palette.text.secondary}>
								Mobile:
							</CustomTypography>
							<CustomTypography colorText={theme.palette.text.secondary}>
								{userData?.phoneNumber || ''}
							</CustomTypography>
						</Stack>
						<Stack
							direction="row"
							justifyContent="space-between"
							className="user-info-dialog__row"
							spacing={2}
						>
							<CustomTypography colorText={theme.palette.text.secondary}>
								E-mail:
							</CustomTypography>
							<CustomTypography colorText={theme.palette.text.secondary}>
								{userData?.email || ''}
							</CustomTypography>
						</Stack>
						<Stack
							direction="row"
							justifyContent="space-between"
							className="user-info-dialog__row"
							spacing={2}
						>
							<CustomTypography colorText={theme.palette.text.secondary}>
								Country:
							</CustomTypography>
							<CustomTypography colorText={theme.palette.text.secondary}>
								{userData?.country?.name || ''}
							</CustomTypography>
						</Stack>
						{userData?.inviteLink && (
							<Stack
								direction="row"
								justifyContent="space-between"
								className="user-info-dialog__row"
								spacing={2}
							>
								<CustomTypography colorText={theme.palette.text.secondary}>
									Link Name:
								</CustomTypography>
								<CustomTypography
									colorText={theme.palette.text.secondary}
									className="pseudo-link"
									onClick={goToCustomerTableByLinkName}
								>
									{userData?.inviteLink?.name || ''}
								</CustomTypography>
							</Stack>
						)}

						{/* <Stack
							direction="row"
							justifyContent="space-between"
							className="user-info-dialog__row"
							// alignItems="center"
							spacing={2}
							sx={{
								marginBottom: '6px',
								'& > p:first-of-type': {
									fontWeight: '500',
									lineHeight: '32px',
								},
							}}
						>
							<CustomTypography colorText={theme.palette.text.secondary}>
								Tags:
							</CustomTypography>
							<TagTextField />

						</Stack> */}
					</Paper>
					<Paper elevation={0} className="user-info-action">
						<EditRatesForm
							userDataForm={customerDetailsForm}
							customerInviteLinkFee={customerInviteLinkFee}
							instanceDefaultRewardFees={instanceDefaultRewardFees}
							setIsDisabledBtn={setIsDisabledBtn}
							onSave={onSave}
						/>
					</Paper>
				</StyledDialogContent>
				<StyledDialogActions>
					<SecondaryButton type="button" onClick={closeModal}>
						Cancel
					</SecondaryButton>
					<CustomButton type="submit" form="edit-customer-fee" disabled={isDisabledBtn}>
						Save Changes
					</CustomButton>
				</StyledDialogActions>
			</StyledDialogBody>
		</StyledDialog>
	)
}

export default EditingCustomerDetailsModal

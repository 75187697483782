import axios, { AxiosError, Method } from 'axios'
import { localStorageData } from '../helpers/localStorage'
import { CustomErrorResponse } from '../types/axiosTypes'
import { combineUrl } from './utils'
import { authServices } from './services/authServices'

export const ownAuthAxios = axios.create()

ownAuthAxios.interceptors.response.use(
	async response => response,
	async function (error: AxiosError) {
		// const customError = error?.response?.data as CustomErrorResponse

		if (error.response?.status === 401) {
			try {
				const response = await authServices.refreshToken()
				localStorageData.setData('authData', response)
				if (error.config) {
					error.config.headers!['Authorization'] = `Bearer ${response?.accessToken}`
					return await ownAuthAxios.request(error.config)
				}
			} catch (error) {
				localStorageData.clearAllData()
				window.location.href = '/login'

				return Promise.reject('session expired!')
			}
		}

		// if (
		//   error.response?.status &&
		//   error.response?.status >= 500 &&
		//   error.response?.status < 600 &&
		//   customError.errorCode !== '5000000148' &&
		//   customError.errorCode !== '5000000158'
		// ) {
		//   history.push('/maintenance')
		// }

		// if (error.response?.status === 403) {
		//   // await authServices.logOut()
		//   // localStorageHelper.clearLocalStorage()
		//   // store.dispatch({ type: authConstants.LOGOUT_SUCCESS })
		//   history.push('/')
		//   store.dispatch({ type: errorConstants.SET_ERROR, payload: { errorCode: '403' } })
		// }

		return Promise.reject(error)
	},
)

export const authorizedRequest = async <TRequest, TResponse>(
	path: string[],
	method: Method = 'GET',
	body?: TRequest,
	queryParams?: Record<string, string | number | Array<number | string>>,
	// isCancelledMode?: boolean,
): Promise<TResponse> => {
	const authData = localStorageData.getData('authData')
	const upperCaseMethod = method.toUpperCase()
	const apiUrl = process.env.REACT_APP_BASE_URL || ''
	const defaultApiUrl = 'api/v1/master-agent'

	const requestUri = combineUrl(apiUrl, defaultApiUrl, ...path)

	// const controller = new AbortController()

	// if (isCancelledMode) {
	//   abortControllerObj[url]?.abort()
	//   abortControllerObj[url] = controller
	// }


	let response
	try {
		response = await ownAuthAxios({
			method: upperCaseMethod,
			baseURL: requestUri,
			headers: {
				Authorization: `Bearer ${authData.accessToken}`,
				'Content-Type': 'application/json',
			},
			...(body && { data: body }),
			responseType: 'json',
			params: queryParams,
			paramsSerializer: {
				indexes: null,
			},
			// ...(isCancelledMode && { signal: controller.signal }),
		})
		// controller.abort()
		return response.data as TResponse
	} catch (error) {
		let e = error as AxiosError
		let customErrorResponse = JSON.parse(e.request.response) as CustomErrorResponse
		return Promise.reject(customErrorResponse)
	}
}

export const getFile = async (
	path: string[],
	queryParams?: Record<string, string | number | Array<string | number>>,
) => {
	const authData = localStorageData.getData('authData')
	const apiUrl = process.env.REACT_APP_BASE_URL || ''
	const defaultApiUrl = 'api/v1/master-agent'

	const requestUri = combineUrl(apiUrl, defaultApiUrl, ...path)

	let response
	try {
		response = await ownAuthAxios({
			method: 'GET',
			baseURL: requestUri,
			headers: {
				Authorization: `Bearer ${authData.accessToken}`,
				'Content-Type': 'text/csv',
			},
			responseType: 'blob',
			params: queryParams,
			paramsSerializer: { indexes: null },
		})

		return response
	} catch (error) {
		let e = error as AxiosError
		let customErrorResponse = JSON.parse(e.request.response) as CustomErrorResponse
		return Promise.reject(customErrorResponse)
	}
}

import { createSlice } from '@reduxjs/toolkit'
import {
	CustomerDetails,
	GetCustomersServerResponse,
	InviteLinkForFilter,
	InviterListForFilter,
} from '../../types/customersTypes'
import { customersActionCreators } from '../actionCreators/customersActionCreators'
import { FeeDetailsResponse, LoadState } from '../../types/commonTypes'

type CustomersState = {
	customerList: GetCustomersServerResponse
	customerDetails: {
		userData: CustomerDetails | null
		customerInviteLinkFee: Array<FeeDetailsResponse>
	}
	instanceDefaultRewardFees: Array<FeeDetailsResponse>
	filterList: {
		inviteLinkList: Array<InviteLinkForFilter>
		inviterList: Array<InviterListForFilter>
	}

	filterStateForMyCustomerTable: {
		inviteLinkIds: Array<{ id: string | number; name: string }>
		query: string
	}

	getCustomerListLoadState: LoadState
	getInviteListForFilterLoadState: LoadState
	getInviterListForFilterLoadState: LoadState
	// getCustomerDetailsLoadState: LoadState
}

const initialState: CustomersState = {
	customerList: { hasNextPage: false, items: [], count: 0 },
	customerDetails: {
		userData: null,
		customerInviteLinkFee: [],
	},
	instanceDefaultRewardFees: [],
	filterList: { inviteLinkList: [], inviterList: [] },

	filterStateForMyCustomerTable: {
		inviteLinkIds: [],
		query: '',
	},

	getCustomerListLoadState: {
		isLoading: false,
		isLoaded: false,
	},
	getInviteListForFilterLoadState: {
		isLoading: false,
		isLoaded: false,
	},
	getInviterListForFilterLoadState: { isLoading: false, isLoaded: false },
	// getCustomerDetailsLoadState: { isLoading: false, isLoaded: false },
}

export const customersSlice = createSlice({
	name: 'customers',
	initialState,
	reducers: {
		changeFilterStateForMyCustomerTable: (state, action) => {
			state.filterStateForMyCustomerTable = action.payload
		},
		clearFilterStateForMyCustomerTable: state => {
			state.filterStateForMyCustomerTable = {
				inviteLinkIds: [],
				query: '',
			}
		},
		removeCustomerDetails: state => {
			state.customerDetails = { userData: null, customerInviteLinkFee: [] }
		},
	},
	extraReducers: builder => {
		builder
			.addCase(customersActionCreators.getCustomerList.pending, (state, action) => {
				state.getCustomerListLoadState.isLoading = true
				state.customerList.items =
					action.meta.arg.offset === 0 ? [] : state.customerList.items
			})
			.addCase(customersActionCreators.getCustomerList.fulfilled, (state, action) => {
				state.getCustomerListLoadState.isLoading = false
				state.customerList.hasNextPage = action.payload.hasNextPage
				state.customerList.count = action.payload.count
				state.customerList.items = [...state.customerList.items, ...action.payload.items]
			})
			.addCase(customersActionCreators.getCustomerList.rejected, state => {
				state.getCustomerListLoadState.isLoading = false
			})
			.addCase(customersActionCreators.getInviteLinkListForFilter.pending, state => {
				state.getInviteListForFilterLoadState.isLoading = true
			})
			.addCase(
				customersActionCreators.getInviteLinkListForFilter.fulfilled,
				(state, action) => {
					state.filterList.inviteLinkList = action.payload
					state.getInviteListForFilterLoadState.isLoading = false
				},
			)
			.addCase(customersActionCreators.getInviteLinkListForFilter.rejected, state => {
				state.getInviteListForFilterLoadState.isLoading = false
			})
			.addCase(customersActionCreators.getInviterListForFilter.pending, state => {
				state.getInviterListForFilterLoadState.isLoading = true
			})
			.addCase(
				customersActionCreators.getInviterListForFilter.fulfilled,
				(state, action) => {
					state.filterList.inviterList = action.payload
					state.getInviterListForFilterLoadState.isLoading = false
				},
			)
			.addCase(customersActionCreators.getInviterListForFilter.rejected, state => {
				state.getInviterListForFilterLoadState.isLoading = false
			})
			.addCase(customersActionCreators.getCustomerDetails.fulfilled, (state, action) => {
				state.customerDetails.userData = action.payload
			})

			.addCase(
				customersActionCreators.getCustomerInviteLinkFee.fulfilled,
				(state, action) => {
					state.customerDetails.customerInviteLinkFee = action.payload
				},
			)
			.addCase(
				customersActionCreators.getInstanceDefaultRewardFee.fulfilled,
				(state, action) => {
					state.instanceDefaultRewardFees = action.payload.fees
				},
			)
	},
})

export const {
	removeCustomerDetails,
	changeFilterStateForMyCustomerTable,
	clearFilterStateForMyCustomerTable,
} = customersSlice.actions

export default customersSlice.reducer

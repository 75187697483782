import { Currency, FeeDetailsRequest, FeeDetailsResponse } from './commonTypes'

type Country = {
	id: number
	code: string
	name: string
}

export type Customer = {
	id: string
	createdDate: string
	firstName: string
	lastName: string
	email: string
	phoneNumber: string
	country: Country | null
	allTransactionsCount: number
	notRedeemedTransactionsCount: number
	bookedTransactionsSum: number
}

export type GetCustomersServerRequest = {
	offset: number
	pageSize: number
	queryParams?: Record<string, string | Array<number | string>>
}

export type GetCustomersServerResponse = {
	hasNextPage: boolean
	items: Array<Customer>
	count: number
}

export type SetDefaultRates = {
	customerDefaultSpread: string
	customerDefaultFixedFee: string
}

export type InviteLinkForFilter = {
	id: number
	name: string
}

export type InviterListForFilter = {
	id: string
	firstName: string
	lastName: string
}

export type CustomerTableFilterFormType = {
	inviteLinkIds: Array<{ id: string | number; name: string }>
	inviterIds?: Array<{ id: string | number; name: string }>
	query: string
}

export enum OverrideLevelForFee {
	Custom,
	FromLink,
	Default,
}

export type FeeDetails = {
	currency: string
	value: {
		fixedValue: number
		percentValue: number
	}
}

export type FeeDetailsForm = {
	currency: string
	fixedValue: string | number
	percentValue: string | number
}

export type FeeDetailsForForm = {
	currency: Currency
	fixedValue: string | number
	percentValue: string | number
}

export type CustomerDetailsForForm = {
	overrideLevel: OverrideLevelForFee
	fees: Array<FeeDetailsForForm>
}

export type CustomerDetailsForm = {
	overrideLevel: OverrideLevelForFee
	fees: Array<FeeDetailsForm>
}

export type CustomerDetails = {
	id: string
	firstName: string
	lastName: string
	dateOfBirth: string
	email: string
	phoneNumber: string
	country: Country | null
	overrideLevel: OverrideLevelForFee
	fees: Array<FeeDetailsResponse>
	inviteLink: { id: string | number; name: string } | null
}

export type SetCustomerFeeServerRequestBody = {
	overrideLevel: OverrideLevelForFee
	fees: Array<FeeDetailsRequest>
}

export type SetCustomerFeeServerRequest = {
	userId: string
	body: SetCustomerFeeServerRequestBody
}

export type GetInstanceDefaultRewardFeeResponse = {
	fees: Array<FeeDetailsResponse>
}

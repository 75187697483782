import { styled, useTheme } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { NavLink } from 'react-router-dom'

type MainMenuItemStyledProps = {
	isOpenMenu: boolean
}

const MainMenuItemStyled = styled(ListItem, {
	shouldForwardProp: prop => prop !== 'isOpenMenu',
})<MainMenuItemStyledProps>(({ theme, isOpenMenu }) => ({
	display: 'block',
	width: '90%',
	marginLeft: 'auto',
	marginBottom: '10px',

	'.MuiListItemButton-root': {
		minHeight: 48,
		justifyContent: isOpenMenu ? 'initial' : 'center',
		padding: '8px 20px',
		...(theme.palette.mode === 'light'
			? {
					background: '#fff',
					border: '1px solid #082280',
					borderRight: 'none',
					color: '#082280',
					svg: {
						path: {
							fill: '#082280',
						},
					},
			  }
			: {
					background: '#2A3144',
					color: '#fff',
			  }),
		borderBottomLeftRadius: '50px',
		borderTopLeftRadius: '50px',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		'&:hover': {
			...(theme.palette.mode === 'light'
				? {
						background: '#082280',
						color: '#fff',
						svg: {
							path: {
								fill: '#fff',
							},
						},
				  }
				: {
						background: '#fff',
						color: '#2A3144',
						svg: {
							path: {
								fill: '#2A3144',
							},
						},
				  }),
		},
		'.MuiListItemIcon-root': {
			minWidth: 0,
			marginRight: isOpenMenu ? '24px' : 'auto',
			justifyContent: 'center',
			color: 'inherit',
		},
		'.MuiListItemText-root': {
			opacity: isOpenMenu ? 1 : 0,
		},
	},
	'.active .MuiListItemButton-root': {
		...(theme.palette.mode === 'light'
			? {
					background: '#082280',
					color: '#fff',
					svg: {
						path: {
							fill: '#fff',
						},
					},
			  }
			: {
					background: '#fff',
					color: '#2A3144',
					svg: {
						path: {
							fill: '#2A3144',
						},
					},
			  }),
	},
}))

type MainMenuItemProps = {
	isOpenMenu: boolean
	icon: React.ReactNode
	text: string
	path: string
}

const MainMenuItem = ({ isOpenMenu, icon, text, path }: MainMenuItemProps) => {
	const theme = useTheme()

	return (
		<MainMenuItemStyled disablePadding isOpenMenu={isOpenMenu}>
			<NavLink to={path}>
				<ListItemButton>
					<ListItemIcon>{icon}</ListItemIcon>
					<ListItemText primary={text} />
				</ListItemButton>
			</NavLink>
		</MainMenuItemStyled>
	)
}

export default MainMenuItem

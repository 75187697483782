import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

type StyledCustomTypographyProps = {
	colorText: string
}

const StyledCustomTypography = styled(Typography, {
	shouldForwardProp: prop => prop !== 'colorText',
})<StyledCustomTypographyProps>(({ colorText }) => ({
	display: 'inline-block',
	color: colorText,
	// fontWeight: bold,
	'&.pseudo-link': {
		textDecoration: 'underline',
		':hover': {
			cursor: 'pointer',
		},
	},
}))

type CustomTypographyProps = {
	children: string | undefined
	colorText: string
	className?: string
	onClick?: (event: React.MouseEvent<HTMLElement>) => void
	// bold?: string
}

const CustomTypography = ({
	children,
	colorText,
	onClick,
	className,
}: CustomTypographyProps) => {
	return (
		<StyledCustomTypography
			colorText={colorText}
			{...(onClick && { onClick })}
			className={className}
		>
			{children}
		</StyledCustomTypography>
	)
}

export default CustomTypography

import { styled } from '@mui/material'
import { LegacyRef, useMemo } from 'react'
import { TableConfig } from '../../../types/tableTypes'
// import useScrollBar from '../../../hooks/useScrollBar'
import TableRow from './TableRow'
import LazyCustomersTableHeader from './LazyCustomersTableHeader'
import CircularProgress from '@mui/material/CircularProgress'

const StyledLoading = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: '20px 0',
}))

type StyledLazyCustomersTableProps = {
	gridTemplateColumns?: string
}

const StyledLazyCustomersTable = styled('div', {
	shouldForwardProp: prop => prop !== 'gridTemplateColumns',
})<StyledLazyCustomersTableProps>(({ theme, gridTemplateColumns }) => ({
	minWidth: '355px',
	height: 'calc(100dvh - 305px)',
	'.table-wrap': {
		overflowX: 'auto',
		overflowY: 'auto',
		// maxHeight: 'calc(100dvh - 305px)',
		height: '100%',
		'&': {
			scrollbarWidth: 'auto',
			scrollbarColor: 'rgba(0, 0, 0, 0.4) #ffffff',
		},

		'&::-webkit-scrollbar': {
			width: '16px',
		},

		'&::-webkit-scrollbar-track': {
			background: '#ffffff',
		},

		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0, 0, 0, 0.4)',
			borderRadius: '10px',
			border: '3px solid #ffffff',
		},
	},
	'.table-header': {
		position: 'sticky',
		top: '0',
		zIndex: 10,
		display: 'grid',
		gridTemplateColumns:
			'minmax(140px, 1fr) minmax(200px, 1fr) minmax(100px, 1fr) minmax(180px, 1fr) repeat(3, minmax(160px, 1fr))  minmax(80px, 1fr)',
		// gridTemplateColumns: gridTemplateColumns,
	},
	'.table-body': {
		// height: '100%',
		// maxHeight: '100%',
	},

	[theme.breakpoints.down('md')]: {
		height: 'calc(100dvh - 310px)',
	},
	[theme.breakpoints.down('sm')]: {
		height: 'calc(100dvh - 290px)',

		'.table-header': {
			// gridTemplateColumns: 'repeat(3, minmax(150px, 1fr)) minmax(80px, 90px)',
			gridTemplateColumns:
				'minmax(110px, 1fr) minmax(170px, 1fr) minmax(70px, 1fr) minmax(150px, 1fr) repeat(3, minmax(125px, 1fr))  minmax(60px, 1fr)',
		},
	},
}))

type LazyCustomersTableProps<T> = {
	tableConfig: TableConfig<T>
	tableData: T[]
	onClick?: (id: string | number) => void
	sentryRef: LegacyRef<HTMLDivElement>
	rootRef: LegacyRef<HTMLDivElement>
	gridTemplateColumns?: string
	loading: boolean
	hasNextPage: boolean
}

const LazyCustomersTable = <T extends { extId?: string; id?: string | number }>({
	tableConfig,
	tableData,
	rootRef,
	sentryRef,
	onClick,
	// minTableWidth,
	loading,
	hasNextPage,
	gridTemplateColumns,
}: LazyCustomersTableProps<T>) => {
	// const tableBodyRef = useRef<HTMLDivElement | null>(null)
	// const tableRef = useRef<HTMLDivElement | null>(null)

	// const hasScroll = true

	// useScrollBar(tableBodyRef, hasScroll)
	// useScrollBar(tableRef, hasScroll)
	const isExistOnClick = useMemo(() => Boolean(onClick), [onClick])

	return (
		<StyledLazyCustomersTable gridTemplateColumns={gridTemplateColumns}>
			<div className="table-wrap" ref={rootRef}>
				<LazyCustomersTableHeader tableConfig={tableConfig} />
				<div
					className="table-body"

					// style={{ height: hasScroll ? '435px' : 'auto' }}
				>
					{tableData.map((rowData, rowIndex) => (
						<TableRow<T>
							// sentryRef={sentryRef}
							key={rowData.extId || rowData.id}
							rowData={rowData}
							rowIndex={rowIndex}
							tableConfig={tableConfig}
							isExistOnClick={isExistOnClick}
							onClick={onClick}
						/>
					))}
					{(loading || hasNextPage) && (
						<StyledLoading ref={sentryRef}>
							<CircularProgress />
						</StyledLoading>
					)}
				</div>
			</div>
		</StyledLazyCustomersTable>
	)
}

export default LazyCustomersTable

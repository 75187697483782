import CustomFlagComponent from '../../components/customers/CustomFlagComponent'
import CustomersTableMenuButton from '../../components/customers/customersTable/CustomersTableMenuButton'
import { Customer } from '../../types/customersTypes'
import { TableConfig } from '../../types/tableTypes'
import { getFormattedDate } from '../utils/dateUtils'

export const customersTableConfig = (): TableConfig<Customer> => {
	return {
		columns: [
			{
				id: 'created-date',
				header: 'Created Date',
				contentColumn: item => getFormattedDate(item.createdDate, 'dd-MM-yyyy'),
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
				className: 'created-date',
			},
			{
				id: 'customer-name',
				header: 'Customer Name',
				contentColumn: item => `${item.firstName} ${item.lastName}`,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
				className: 'customer-name',
			},
			{
				id: 'country',
				header: 'Country',
				contentColumn: item => (
					<>
						{item?.country?.code ? (
							<CustomFlagComponent countryCode={item?.country?.code} />
						) : null}
					</>
				),
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
				className: 'country',
			},
			{
				id: 'phone-number',
				header: 'Phone Number',
				contentColumn: item => item.phoneNumber,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
				className: 'phone-number',
			},
			// {
			// 	id: 'status',
			// 	header: 'Status',
			// 	contentColumn: item => item.status,
			// 	// width: '20%',
			// 	width: {
			// 		minWidth: 'inherit',
			// 		width: 'inherit',
			// 	},
			// 	className: 'status',
			// },
			{
				id: 'tx',
				header: 'All Transactions',
				contentColumn: item => item.allTransactionsCount,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
				className: 'tx',
			},
			{
				id: 'not-redeemed-tx',
				header: 'Not Redeemed',
				contentColumn: item => item.notRedeemedTransactionsCount,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
				className: 'not-redeemed-tx',
			},
			{
				id: 'currently-booked',
				header: 'Currently Booked',
				contentColumn: item => `$${item.bookedTransactionsSum.toFixed(2)}`,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
				className: 'currently-booked',
			},
			{
				id: 'menu',
				header: '',
				contentColumn: item => <CustomersTableMenuButton userId={item.id} />,
				// width: '20%',
				width: {
					minWidth: 'inherit',
					width: 'inherit',
				},
				className: 'menu',
			},
		],
	}
}

import { OverrideLevelForFee } from '../../types/customersTypes'

export const getOverrideLevelOptions = (isCustomerFromLink: boolean) => {
	return isCustomerFromLink
		? [
				{
					value: Number([OverrideLevelForFee.FromLink]),
					label: 'From Link Fee',
				},
				{ value: Number([OverrideLevelForFee.Custom]), label: 'Custom' },
				{ value: Number([OverrideLevelForFee.Default]), label: 'Default' },
		  ]
		: [
				{ value: Number([OverrideLevelForFee.Custom]), label: 'Custom' },
				{ value: Number([OverrideLevelForFee.Default]), label: 'Default' },
		  ]
}

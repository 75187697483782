import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import { Customer } from '../../types/metricsTypes'
// import { metricsActionCreators } from '../actionCreators/metricsActionCreators'

type MetricsState = {
	isLoading: boolean
	isLoaded: boolean
	error: string
	// customers: Array<Customer> | []
	// customer?: Customer
}

const initialState: MetricsState = {
	isLoading: false,
	isLoaded: false,
	error: '',
	// customers: [],
}

export const metricsSlice = createSlice({
	name: 'metrics',
	initialState,
	reducers: {
		// getCurrentCustomer: (state, action) => {
		// 	state.customer = state.customers.find(customer => customer.id === action.payload)
		// },
	},
	extraReducers: builder => {
		// builder
			// .addCase(metricsActionCreators.getCustomers.pending, state => {
			// 	state.isLoading = true
			// 	state.error = ''
			// })
			// .addCase(metricsActionCreators.getCustomers.fulfilled, (state, action) => {
			// 	state.isLoading = false
			// 	state.isLoaded = true
			// 	// state.customers = action.payload
			// })

		// .addCase()
	},
})

// export const { getCurrentCustomer } = metricsSlice.actions

export default metricsSlice.reducer

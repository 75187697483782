import { styled } from '@mui/material'
import * as Flags from 'country-flag-icons/react/3x2'

const StyledCustomFlagComponent = styled('div')({
	// svg: {
	// 	verticalAlign: 'middle',
	// },
})

type CustomFlagComponentProps = {
	countryCode: string
}

const CustomFlagComponent = ({ countryCode }: CustomFlagComponentProps) => {
	const FlagComponent = Flags[`${countryCode}`]

	return (
		<>
			<div style={{ width: '28px', paddingRight: '5px' }}>
				<FlagComponent />
			</div>
			<div>{countryCode}</div>
		</>
	)
}

export default CustomFlagComponent

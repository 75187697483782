import { getFile } from '../api/authorizeRequest'
import { FilterStateForReports } from '../types/reportsTypes'

const fileDownload = require('js-file-download')

const downloadTransactionReportCsv = (
  queryParams: Record<string, string | number | Array<string | number>>,
) => {
  const path = ['top-ups', 'report']

  return getFile(path, queryParams)
}

const downloadRBZReportCSV = async (
  queryParams: Record<string, string | number | Array<string | number>>,
) => {
  const path = ['top-ups', 'report', 'rbz']

  const result = await getFile(path, queryParams)

  const file = await result.data
  const header = result.headers['content-disposition'] || ''
  const fileName = header.match(/filename=(.*);/) || []
  const test = fileName.length > 0 && fileName[1]

  await fileDownload(file, test)
}

const downloadCustomerReportCsv = async (
  queryParams: Record<string, string | number | Array<string | number>>,
) => {
  const path = ['users', 'report']

  const result = await getFile(path, queryParams)

  const file = await result.data
  const header = result.headers['content-disposition'] || ''
  const fileName = header.match(/filename=(.*);/) || []
  const test = fileName.length > 0 && fileName[1]

  await fileDownload(file, test)
}

export const reportsServices = { downloadTransactionReportCsv, downloadRBZReportCSV, downloadCustomerReportCsv }

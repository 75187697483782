import * as yup from 'yup'

const loginScheme = yup.object({
	email: yup
		.string()
		.typeError('Login Invalid')
		.trim()
		.matches(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			'Login Invalid',
		)
		.required('Required field'),
	password: yup.string().typeError('Password Invalid').trim().required('Required field'),
})

export const authSchemes = {
	loginScheme,
}
